<template>
  <atkm-admin-container>
    <atkm-forms :editMode="true" />
  </atkm-admin-container>
</template>

<script>
import AtkmAdminContainer from "@/components/admin/AtkmAdminContainer";
import AtkmForms from "@/components/connect/AtkmForms";

export default {
  name: "AtkmViewFormsPage",
  components: {
    AtkmForms,
    AtkmAdminContainer,
  },
}
</script>

<style lang="scss" scoped>

</style>