<template>
  <div class="atkm-giving-online-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h1 class="atkm-header">Online</h1>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-credit-debit-qr-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <b-img
            fluid
            :width="imageWidth"
            :src="require('@/assets/img/paypal-qr-code.png')">
        </b-img>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-credit-debit-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <a class="atkm-button-link"
           target="_blank"
           :href="paypalLink">
          Credit or Debit
        </a>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { getWidth } from "@/utils/Images";
import { getPaypalLink } from "@/utils/Url";
import { BRow, BCol } from "bootstrap-vue-next";

export default {
  name: "AtkmOnlineGiving",
  computed: {
    paypalLink() {
      return getPaypalLink();
    },
    imageWidth() {
      return getWidth();
    }
  },
  components: {
    BRow,
    BCol
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-online-giving.scss";
</style>