<template>
  <div class="atkm-ministries">
    <atkm-header />
    <atkm-wrapped-banner
        id="atkm-ministries-banner"
        title-class="no-text"
        :img-height="imageHeight"
        title="MINISTRIES"
        :slides="ministriesSlides" />
    <div class="atkm-container">
      <atkm-ministry
          v-for="(ministry, index) in sortedMinistries"
          :key="ministry.name"
          :name="ministry.name"
          :description="ministry.description"
          :separator="index !== ministries.length - 1"/>
    </div>
    <atkm-footer />
  </div>
</template>

<script>
import { getBannerSlideInterval} from "@/utils/Banner";
import { getBannerHeight } from "@/utils/Header";
import AtkmMinistry from "@/components/ministries/AtkmMinistry.vue";
import AtkmHeader from "@/components/AtkmHeader.vue";
import AtkmFooter from "@/components/AtkmFooter";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";

export default {
  name: "AtkmMinistries",
  computed: {
    slideInterval() {
      return getBannerSlideInterval()
    },
    imageHeight() {
      return getBannerHeight();
    },
    sortedMinistries() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.ministries.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
  },
  data() {
    return {
      ministries: [
        {
          name: "Helping Hands Outreach Ministry",
          description: "Our mission is to serve the members of our local church and our communities. Our hands are inspired to feed the hungry, clothe the naked, visit the sick and shut-in, pray for and comfort the widows."
        },
        {
          name: "Kingdom Kids Youth Ministry",
          description: "Our mission is to inform the youth about their creator; inspire them to desire a relationship with Him. Instruct them through biblical teachings how to have a relationship with Him and infuse them with the understanding necessary to fulfill His purpose for their lives."
        },
        {
          name: "S.A.S.S.Y Women's Ministry",
          description: "Our mission is to bring healing and encouragement to the women of our local Church and surrounding communities through fellowship, prayer, workshops and conferences."
        },
        {
          name: "Kingdom Men's Ministry",
          description: "Our mission is to equip men to be the Kingdom leaders of the home, church, and community through fellowship, stewardship, and discipleship."
        },
        {
          name: "Security Ministry",
          description: "Our mission is to provide a safe environment for all of our members and visitors while they are in service. This ministry attempts to take away distractions from members and visitors concerning personal safety and property protection so that attention can be devoted to worshipping the Lord during service."
        },
        {
          name: "Dance Ministry",
          description: "Our mission is to lead God’s people into his presence through worship in dance.  As we worship God in the form dance, His presence inhabits our sanctuary creating an atmosphere that is conducive to a mighty move of God."
        },
        {
          name: "Finance Ministry",
          description: "Our mission is to handle the Churches finances with integrity and accuracy to ensure God’s purpose is served."
        },
        {
          name: "Greeter's Ministry",
          description: "Our mission is to promote a climate of acceptance, warmth, and welcome to our parishioners, visitors and newcomers to the ministry."
        },
        {
          name: "Usher Ministry",
          description: "Our mission is to provide a welcoming and orderly environment during our worship service.  We assist with seating, receiving offerings, Communion and responding to emergencies."
        },
        {
          name: "Praise & Worship Ministry",
          description: "Our mission is to prepare the hearts and minds of God’s people to receive the Word of God through song.  Accompanied by musicians the Praise Team leads the congregation into the presence of God with music and song."
        },
        {
          name: "Sound Technician Ministry",
          description: "Our mission is to ensure that those leading the people of God in praise, worship and the Word of God can do so without any interruption in sound.  We strive to ensure that those up leading us into the presence of God are heard with clarity."
        },
        {
          name: "Media Ministry",
          description: "Our mission is to ensure our members and visitors receive the media material necessary to help them grow in their faith.  We do this through producing, editing, arranging, and duplicating audio recordings of worship services."
        },
        {
          name: "Maintenance Ministry",
          description: "Our mission is to provide general and specific maintenance as needed at our Church.  We realize that maintaining Gods house is a ministry that is vitally important to the success of the overall ministry.  Therefore, we are always on call to provide maintenance services as needed."
        },
        {
          name: "Pastoral Care Ministry",
          description: "Our mission is to care for our pastors in all aspects.  As a result, our job is to come up with, plan out and execute events, services, fundraisers and special times of thanks for our pastors."
        },
        {
          name: "Prayer Ministry",
          description: "Our mission is to invoke the presence of God to move supernaturally, in our ministries, homes, communities, and nation."
        }
      ],
      ministriesSlides: [
        {
          image: require("@/assets/banners/theme-bg.png")
        },
      ]
    }
  },
  components: {
    AtkmMinistry,
    AtkmHeader,
    AtkmFooter,
    AtkmWrappedBanner
  },
}
</script>

<style scoped>

</style>