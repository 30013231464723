<template>
  <b-carousel
    no-hover-pause
    :ref="id"
    :id="id"
    @slide="onSlidingStart"
    :interval="interval"
    :img-height="imgHeight"
    :img-width="imgWidth"
    :controls="showControls"
    :indicators="showIndicators">
    <b-carousel-slide
      v-for="(slide, index) in slides"
      :key="index"
      :interval="interval"
      :img-src="slide.image"
      :active="index === 0"
      fluid>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue-next";
import { getBannerSlideInterval } from "@/utils/Banner";

export default {
  name: "AtkmBanner",
  mounted() {
    this.$refs[this.id].resume();
  },
  computed: {
    showControls() {
      return this.slides.length > 1;
    },
    showIndicators() {
      return this.slides.length > 1;
    },
  },
  methods: {
    onSlidingStart(slide) {
      setTimeout(() => {
        this.$emit('slide-start', slide.to);
      }, 300)
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    slides: {
      type: Array,
      required: true,
    },
    imgHeight: {
      type: String,
      required: false,
    },
    imgWidth: {
      type: String,
      required: false,
      default: "1024",
    },
    interval: {
      type: String,
      required: false,
      default: `${getBannerSlideInterval()}`,
    },
    slidingStart: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  components: {
    BCarousel,
    BCarouselSlide,
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/atkm-banner.scss";
</style>