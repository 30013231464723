<template>
  <div class="atkm-our-connections-container">
    <atkm-header />
    <atkm-wrapped-banner
        id="atkm-our-connections-banner"
        title-class="no-text"
        :img-height="imageHeight"
        title="OUR CONNECTION"
        :slides="ourConnectionsSlides" />
    <atkm-our-connections-info />
    <atkm-footer />
  </div>
</template>

<script>
import {getBannerHeight} from "@/utils/Header";
import AtkmHeader from "@/components/AtkmHeader";
import AtkmFooter from "@/components/AtkmFooter";
import AtkmOurConnectionsInfo from "@/components/about/AtkmOurConnectionsInfo";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";

export default {
  name: "AtkmOurConnections",
  computed: {
    imageHeight() {
      return getBannerHeight();
    }
  },
  data() {
    return {
      ourConnectionsSlides: [
        {
          image: require("@/assets/banners/theme-bg.png")
        }
      ],
    }
  },
  components: {
    AtkmFooter,
    AtkmHeader,
    AtkmOurConnectionsInfo,
    AtkmWrappedBanner,
  }
}
</script>

<style scoped>

</style>