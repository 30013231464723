<template>
  <div class="atkm-container">
    <b-row class="atkm-service-times-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-header">Sundays @ 11:00 A.M.</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-header">Wednesdays @ 7:30 P.M.</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text">Pre-Service prayer starts 15 minutes prior to service</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-corporate-prayer-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text">Corporate Prayer every 3<sup>rd</sup> Wednesday of the month</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-events-link-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <a class="atkm-button-link"
           href="/events">
          Events
        </a>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AtkmServiceTimes"
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-service-times.scss";
</style>