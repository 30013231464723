<template>
  <div class="atkm-container">
    <b-row>
      <b-col>
        <h1 class="atkm-header">Address</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <i @click="onLocationClick"
           class="fa-sharp fa-solid fa-location-dot atkm-location-icon"></i>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">417 N. Grove Street<br />Eustis, FL, 32726</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <atkm-separator />
    <b-row>
      <b-col>
        <h1 class="atkm-header">Telephone</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <i @click="onPhoneClick"
           class="fa-solid fa-phone atkm-location-icon"></i>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">(352) 589-8153</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <atkm-separator />
    <b-row>
      <b-col>
        <h1 class="atkm-header">Mailing Address</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <i class="fa-sharp fa-solid fa-location-dot atkm-location-icon no-hover"></i>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">Advancing The Kingdom Ministry<br />P.O. BOX 350871<br />Grand Island, FL, 32735</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <atkm-separator />
    <b-row>
      <b-col>
        <h1 class="atkm-header">Email</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <i @click="onEmailClick"
           class="fa-solid fa-at atkm-location-icon"></i>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">atkm352@gmail.com</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <atkm-separator />
    <atkm-email-us-form />
  </div>
</template>

<script>
import AtkmSeparator from "@/components/AtkmSeparator";
import AtkmEmailUsForm from "@/components/connect/AtkmEmailUsForm";

export default {
  name: "AtkmContactInfo",
  components: {AtkmSeparator, AtkmEmailUsForm},
  methods: {
    onEmailClick() {
      window.open("mailto:atkm352@gmail.com", "_self");
    },
    onLocationClick() {
      window.open("https://www.google.com/maps/dir/28.4327936,-81.6054272/advancing+the+kingdom/@28.6385868,-81.7746689,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x88e7a337ca879357:0x1c4ffc3a9400805!2m2!1d-81.6827916!2d28.8575899?entry=ttu", "_blank");
    },
    onPhoneClick() {
      window.open("tel:3525898153", "_blank");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-contact-info.scss";
</style>