<template>
  <atkm-admin-container :show-footer="false">
    <atkm-events
        @delete-event="handleDeleteEvent"
        admin
        :show-header="false"
        title="ADMIN EVENTS"/>
    <b-modal ref="deleteEventModal" title="Delete Event Confirmation" hide-footer>
      <p class="atkm-confirmation-modal-text">Would you like to delete this event?</p>
      <b-button class="mt-3 atkm-add-event-button" variant="primary" @click="deleteEvent">Delete Event</b-button>
      <b-button class="mt-3 atkm-cancel-add-button" variant="primary" @click="closeModal">Close</b-button>
    </b-modal>
  </atkm-admin-container>
</template>

<script>
import axios from "axios";

import { getBannerHeight } from "@/utils/Header";
import { epochToDayOfTheWeek, epochToDate, epochToMeridiem, epochToMonth, epochToTime } from "@/utils/Date";

import AtkmAdminContainer from "@/components/admin/AtkmAdminContainer";
import AtkmEvents from "@/components/events/AtkmEvents";

export default {
  name: "AtkmViewEventsPage",
  computed: {
    imageHeight() {
      return getBannerHeight()
    }
  },
  beforeMount() {
    this.loading = true;
    axios
      .get(`${process.env.VUE_APP_ATKM_API}/v1/event`)
      .then((response) => {
        this.events = response.data.events;
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    wrappedEpochToDayOfTheWeek(epoch) {
      return epochToDayOfTheWeek(epoch);
    },
    wrappedEpochToDate(epoch) {
      return epochToDate(epoch);
    },
    wrappedEpochToMonth(epoch) {
      return epochToMonth(epoch);
    },
    wrappedEpochToTime(epoch) {
      return epochToTime(epoch);
    },
    wrappedEpochToMeridiem(epoch) {
      return epochToMeridiem(epoch);
    },
    handleDeleteEvent(id) {
      this.openDeleteEventModal(id);
    },
    openDeleteEventModal(id) {
      this.eventToDelete = id;
      this.$refs.deleteEventModal.show();
    },
    closeModal() {
      this.$refs.deleteEventModal.hide();
    },
    deleteEvent() {
      const axiosInstance = new axios.create({
        withCredentials: true
      });
      const nonce = document.getElementById('atkm-id').value;
      axiosInstance
        .delete(`${process.env.VUE_APP_ATKM_API}/v1/event/${this.eventToDelete}`,{
          headers: {
            'X-WP-Nonce': nonce
          }
        })
        .then(() => {
          this.closeModal();
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    AtkmAdminContainer,
    AtkmEvents,
  },
  data() {
    return {
      events: [],
      loading: false,
      error: false,
      eventToDelete: null,
      slides: [
        {
          image: require("@/assets/banners/theme-bg.png")
        }
      ]
    };
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-admin-view-events.scss";
</style>