<template>
  <div class="atkm-header-container">
    <b-navbar>
      <b-navbar-brand href="/">
        <b-img
            fluid
            :width="logoWidth"
            :src="require('@/assets/logos/logo-circle.png')"
            alt="Advancing The Kingdom Ministry"
            class="atkm-header-logo" />
      </b-navbar-brand>
      <b-navbar-nav id="atkm-navbar-desktop">
        <b-nav-item-dropdown class="atkm-nav-link drop-down"
                             menu-class="atkm-nav-link-list border-1 rounded-0"
                             text="About"
                             no-caret>
          <b-dropdown-item  href="/about/our-pastors">Our Pastors</b-dropdown-item>
          <b-dropdown-item  href="/about/our-mission">Our Mission</b-dropdown-item>
          <b-dropdown-item  href="/about/our-beliefs">Our Beliefs</b-dropdown-item>
          <b-dropdown-item  href="/about/our-connection">Our Connection</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
            @mouseover="onMouseHover"
            @mouseout="onMouseOut"
            class="atkm-nav-link"
            href="/ministries">Ministries</b-nav-item>
        <b-nav-item-dropdown class="atkm-nav-link drop-down"
                             menu-class="atkm-nav-link-list border-1 rounded-0"
                             text="Events"
                             no-caret>
          <b-dropdown-item href="/events/calendar">Calendar</b-dropdown-item>
          <b-dropdown-item href="/events">Upcoming Events</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
            @mouseover="onMouseHover"
            @mouseout="onMouseOut"
            ref="givingLink"
            class="atkm-nav-link"
            href="/giving">Giving</b-nav-item>
        <b-nav-item
            @mouseover="onMouseHover"
            @mouseout="onMouseOut"
            ref="watchLink"
            class="atkm-nav-link"
            href="/watch">Watch</b-nav-item>

        <b-nav-item-dropdown class="atkm-nav-link drop-down"
                             menu-class="atkm-nav-link-list border-1 rounded-0"
                             text="Connect"
                             no-caret>
          <b-dropdown-item  href="/connect/contact-us">Contact Us</b-dropdown-item>
          <b-dropdown-item  href="/connect/prayer-request">Prayer Request</b-dropdown-item>
          <b-dropdown-item  href="/connect/forms">Forms</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
            ref="donateLink"
            class="atkm-nav-link donate-button"
            target="_blank"
            id="donate-button"
            :href="paypalLink">Donate</b-nav-item>
      </b-navbar-nav>
      <b-nav-item
          ref="donateLink"
          class="atkm-nav-link donate-button"
          target="_blank"
          id="donate-button-small"
          :href="paypalLink">Donate</b-nav-item>
      <i
          @click="onToggleMenu"
          class="fa-solid fa-bars atkm-collapse-icon"></i>
    </b-navbar>
    <div
        v-show="state.menuExpanded"
        id="atkm-navbar-phone">
      <b-collapse v-model="state.menuExpanded">
        <b-navbar-nav class="justify-content-center">
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/about/our-pastors">Our Pastors</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/about/our-mission">Our Mission</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/about/our-beliefs">Our Beliefs</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/about/our-connection">Our Connection</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/ministries">Ministries</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/events/calendar">Calendar</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/events">Events</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/giving">Giving</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/watch">Watch</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/connect/contact-us">Contact Us</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/connect/prayer-request">Prayer Request</b-nav-item>
          <hr />
          <b-nav-item
              class="atkm-nav-link collapsible"
              href="/connect/forms">Forms</b-nav-item>
          <hr />
          <b-nav-item
              id="atkm-collapsible-donate-link"
              class="atkm-nav-link collapsible"
              :href="paypalLink"
              target="_blank">Donate</b-nav-item>
          <hr />
        </b-navbar-nav>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { getPaypalLink } from "@/utils/Url";
import { BNavbarNav, BCollapse, BNavbar, BNavbarBrand, BImg } from 'bootstrap-vue-next';

export default {
  name: "AtkmHeader",
  computed: {
    logoWidth() {
      return window.innerWidth < 400 ? 150 : 200;
    },
    paypalLink() {
      return getPaypalLink();
    }
  },
  data() {
    return {
      state: {
        menuExpanded: false,
      }
    }
  },
  methods: {
    isSmallerDevice() {
      return window.innerWidth < 768;
    },
    // HACK: This is a hack to circumvent the fact that the navbar doesn't have a hover state
    onMouseHover(event) {
      event.target.style.color = "rgba(115,115, 115, 0.7)"
    },
    // HACK: This is a hack to circumvent the fact that the navbar doesn't have a hover state
    onMouseOut(event) {
      event.target.style.color = "rgb(28,28, 28)"
    },
    onToggleMenu() {
      this.state.menuExpanded = !this.state.menuExpanded;
    }
  },
  components: {
    BNavbar,
    BNavbarBrand,
    BImg,
    BNavbarNav,
    BCollapse,
  },
}
</script>

<style lang="scss">
@import "../scss/atkm-header.scss";
</style>