<template>
  <atkm-header />
  <slot></slot>
  <atkm-footer v-if="showFooter"/>
</template>

<script>
import AtkmHeader from "@/components/admin/AtkmHeader";
import AtkmFooter from "@/components/AtkmFooter";

export default {
  name: "AtkmAdminContainer",
  props: {
    showFooter: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  components: { AtkmHeader, AtkmFooter }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-admin-container.scss";
</style>