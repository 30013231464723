<template>
  <div class="atkm-ministry-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h1 class="atkm-header">{{ name }}</h1>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text normal-text">{{ description }}</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-separator-row" v-if="separator">
      <b-col cols="4"></b-col>
      <b-col cols="4">
        <hr class="atkm-separator"/>
      </b-col>
      <b-col cols="4"></b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow} from "bootstrap-vue-next";

export default {
  name: "AtkmMinistry",
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    separator: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    BCol,
    BRow
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-ministry.scss";
</style>