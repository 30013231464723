<template>
  <atkm-admin-container>
    <b-row>
      <b-col>
        <h1>Add Form</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form @submit="updatePreview">
          <b-form-group
            class="atkm-form-input-group"
            id="formName"
            label="Form Name"
            label-for="name">
            <b-form-input
              id="name"
              v-model="form.name"
              :state="nameState"
              aria-describedby="input-name-feedback"
              required
              placeholder="Enter form name"></b-form-input>

            <b-form-invalid-feedback id="input-name-feedback">
              Please enter a form name between 1 -255 characters.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              class="atkm-form-input-group"
              id="formDescription"
              label="Form Description"
              label-for="description">
            <b-form-input
                id="name"
                v-model="form.description"
                :state="descriptionState"
                aria-describedby="input-description-feedback"
                placeholder="Enter form description"></b-form-input>

            <b-form-invalid-feedback id="input-description-feedback">
              Form description cannot contain more than 255 characters.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              class="atkm-form-input-group"
              id="formDocumentUrl"
              label="Form Document URL"
              label-for="documentUrl">
            <b-form-input
                id="name"
                v-model="form.document_url"
                :state="documentUrlState"
                aria-describedby="input-document-url-feedback"
                required
                placeholder="Enter form document URL"></b-form-input>

            <b-form-invalid-feedback id="input-document-url-feedback">
              Please enter the document's URL between 1 -255 characters.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              class="atkm-form-input-group"
              id="formImageUrl"
              label="Form Image URL"
              label-for="imageUrl">
            <b-form-input
                id="imageUrl"
                v-model="form.image_url"
                :state="imageUrlState"
                aria-describedby="input-image-url-feedback"
                required
                placeholder="Enter form image URL"></b-form-input>

            <b-form-invalid-feedback id="input-image-url-feedback">
              Please enter the image URL between 1 -255 characters.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button
              class="atkm-add-form-button"
              type="submit"
              variant="primary">Update Preview</b-button>
        </b-form>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col>
        <h2>Preview</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <atkm-form-info
            :title="preview.name"
            :description="preview.description"
            :imageUrl="preview.image_url"
            :documentUrl="preview.document_url"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
            v-show="state.preview"
            class="atkm-add-form-button"
            type="submit"
            variant="primary"
            @click="openModal">Add Form</b-button>
      </b-col>
    </b-row>
    <b-modal ref="confirmationModal" title="Add Form Confirmation" hide-footer>
      <p class="atkm-confirmation-modal-text">Would you like to add this form?</p>
      <b-button class="mt-3 atkm-add-form-button" variant="primary" @click="addForm">Add Form</b-button>
      <b-button class="mt-3 atkm-cancel-add-button" variant="primary" @click="closeModal">Close</b-button>
    </b-modal>
  </atkm-admin-container>
</template>

<script>
import AtkmFormInfo from "@/components/connect/AtkmFormInfo";
import AtkmAdminContainer from "@/components/admin/AtkmAdminContainer";
import axios from "axios";

export default {
  name: "AtkmAddFormPage",
  components: {
    AtkmAdminContainer,
    AtkmFormInfo
  },
  computed: {
    nameState() {
      return this.form.name.length > 0 && this.form.name.length <= 255
    },
    descriptionState() {
      return this.form.description.length <= 255;
    },
    documentUrlState() {
      return this.form.document_url.length > 0 && this.form.document_url.length <= 255;
    },
    imageUrlState() {
      return this.form.image_url.length > 0 && this.form.image_url.length <= 255;
    }
  },
  data() {
    return {
      state: {
        preview: false
      },
      preview: {
        name: '',
        description: '',
        separator: false,
        image_url: '',
        document_url: '',
      },
      form: {
        name: '',
        description: '',
        separator: false,
        image_url: '',
        document_url: '',
      }
    }
  },
  methods: {
    addForm() {
      axios
        .post(`${process.env.VUE_APP_ATKM_API}/v1/form`, this.form)
        .then(() => {
          this.closeModal();
          this.$router.push('/admin/forms');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openModal() {
      this.$refs.confirmationModal.show();
    },
    closeModal() {
      this.$refs.confirmationModal.hide();
    },
    updatePreview() {
      this.state.preview = true;
      this.preview = this.form;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/atkm-admin-add-form.scss";
</style>