<template>
  <div class="atkm-container">
    <b-row class="atkm-our-pastors-img-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <b-img
            fluid
            width="550"
            :src="require('@/assets/img/our-pastors.png')"/>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text">Standing strong for over 30 years; twenty nine of those years have been dedicated to laboring in the vineyard. Having faithfully served in every facet of ministry, from bus ministry, youth ministry, worship ministry, prayer ministry, finance ministry, hospitality ministry, marriage ministry and pastoral care ministry.  It was during these times of dedication and loyal service that a passion for holiness, righteousness and kingdom consciousness became their focus for the kingdom of God. Then, God began to speak to them to come hither. They humbly submitted themselves as faithful servants, God counted it not robbery to appoint and anoint them as Shepherds. Being obedient to the call and leading of God's Spirit, Bishop John & Pastor Erica Blair founded “Advancing The Kingdom Ministry”.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text">The anointed duo of Bishop John & Pastor Erica Blair are two physical bodies, operating as “One Flesh” destined by God.  Being knitted together as the spirit has uniquely aligned them that as one thinketh the other may speak it. God has crowned Bishop John & Pastor Erica Blair with the wisdom and knowledge to teach and preach the Word of God with simplicity, under the divine auspices of the Holy Spirit.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text">Having a mandate to advance the Kingdom of God, with a passion and urgency, Bishop John & Pastor Erica Blair has adhered to the call to equip the saints for the work of ministry. They believe you are not truly living until you are functioning in purpose with passion. With that being said, God has given them a vision to inform, inspire, instruct and infuse believers with the understanding necessary to fulfill their God given purpose. Encountering Advancing The Kingdom Ministry will enhance your life as you embark on a spiritually enriched journey to kingdom living and dominion.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text"><em><sup>11</sup>"And he gave some, apostles; and some, prophets; and some, evangelists; and some, pastors and teachers;<br /><sup>12</sup> For the perfecting of the saints, for the work of the ministry, for the edifying of the body of Christ:”</em><br /><b>Ephesians 4:11-12</b></p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AtkmOurPastorsInfo"
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-our-pastors-info.scss";
</style>