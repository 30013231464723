<template>
  <div class="atkm-wrapped-banner-container">
    <div class="atkm-wrapped-banner">
      <atkm-banner
          class="atkm-banner"
          @slide-start="onSlide"
          :id="id"
          :interval="interval"
          :img-height="imgHeight"
          :img-width="imgWidth"
          :slides="slides" />
      <div
          :id="getId"
          class="atkm-wrapped-banner-wrapper" >
        <div class="atkm-wrapped-banner-content" :class="contentClass ? contentClass : ''">
          <h1 class="atkm-wrapped-banner-title" :class="titleClass ? titleClass : ''">{{ title }}</h1>
          <h1 :style="{'display': title ? '' : 'none'}"
              class="atkm-wrapped-banner-text"
              :class="textClass ? textClass : ''"
              v-html="text" />
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtkmBanner from "@/components/AtkmBanner";
import { getBannerSlideInterval} from "@/utils/Banner";

export default {
  name: "AktmWrappedBanner",
  mounted() {
    const prevButton = document.getElementsByClassName("carousel-control-prev");
    for (let i = 0; i < prevButton.length; i++) {
      prevButton[i].style.zIndex = 999;
    }

    const nextButton = document.getElementsByClassName("carousel-control-next");
    for (let i = 0; i < nextButton.length; i++) {
      nextButton[i].style.zIndex = 999;
    }
    const indicators = document.getElementsByClassName("carousel-indicators");
    for (let i = 0; i < indicators.length; i++) {
      indicators[i].style.zIndex = 999;
    }

  },
  computed: {
    getId() {
      return this.id + "-wrapper";
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    interval: {
      type: String,
      required: false,
      default: `${getBannerSlideInterval()}`
    },
    imgHeight: {
      type: String,
      required: false
    },
    imgWidth: {
      type: String,
      required: false
    },
    slides: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    onSlide: {
      type: Function,
      required: false,
      default: () => {}
    },
    contentClass: {
      type: String,
      required: false
    },
    titleClass: {
      type: String,
      required: false
    },
    textClass: {
      type: String,
      required: false
    }
  },
  components: {
    AtkmBanner,
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/atkm-wrapped-banner.scss";
</style>