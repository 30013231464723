<template>
  <b-row>
    <b-col>
      <div
          class="atkm-banner-config success"
          v-if="state.success">
        Updated Successfully
      </div>
      <div
          class="atkm-banner-config error"
          v-if="state.error">
        {{ state.error }}
      </div>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <h1>Select a Page</h1>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-button-group>
        <b-button
            @click="setView('home')"
            :active="state.view === 'home'"
            class="atkm-menu-button">Home</b-button>
        <b-button
            @click="setView('connect')"
            :active="state.view === 'connect'"
            class="atkm-menu-button">Connect</b-button>
        <b-button
            @click="setView('events')"
            :active="state.view === 'events'"
            class="atkm-menu-button">Events</b-button>
        <b-button
            @click="setView('watch')"
            :active="state.view === 'watch'"
            class="atkm-menu-button">Watch</b-button>
        <b-button
            @click="setView('donate')"
            :active="state.view === 'donate'"
            class="atkm-menu-button">Donate</b-button>
      </b-button-group>
    </b-col>
  </b-row>
  <b-row v-if="state.view">
    <b-col>
      <atkm-wrapped-banner
          v-if="slides.length > 0"
          :id="`atkm-banner-${slides.length}`"
          title="BANNER"
          :slides="slides"
          :image-height="432"
          :image-width="1024"/>

      <h2 class="atkm-banner-none" v-else>No Banners Set</h2>
    </b-col>
  </b-row>
  <hr v-if="state.view" />
  <b-row v-if="state.view">
    <b-col>
      <h1>Preview Banners</h1>
    </b-col>
  </b-row>
  <b-row
      v-for="(slide, index) in slides"
      :key="index">
    <b-col cols="4">
      <b-img :src="slide.image" fluid></b-img>
    </b-col>
    <b-col cols="1">
      <label>Banner {{ index + 1}}</label>
    </b-col>
    <b-col cols="6">
      <label>{{ slide.image }}</label>
    </b-col>
    <b-col cols="1">
      <i
          @click="deleteImage(index)"
          class="fa-solid fa-trash"></i>
    </b-col>
  </b-row>
  <b-row v-if="state.view && slides.length === 0">
    <b-col>
      <h2 class="atkm-banner-none">No Banners Set</h2>
    </b-col>
  </b-row>
  <hr v-if="state.view" />
  <b-row v-if="state.view">
    <b-col>
      <h1>Add Banner</h1>
    </b-col>
  </b-row>
  <b-row v-if="state.view">
    <b-col cols="4">
      <b-img :src="addImageUrl" fluid></b-img>
    </b-col>
    <b-col cols="1">
      <label>Banner {{ slides.length + 1}}</label>
    </b-col>
    <b-col cols="6">
      <b-form-group
          class="atkm-form-input-group"
          id="formImageUrl"
          label="Form Image URL"
          label-for="imageUrl">
        <b-form-input
            id="imageUrl"
            v-model="addImageUrl"
            :state="imageUrlState"
            aria-describedby="input-image-url-feedback"
            required
            placeholder="Enter form image URL"></b-form-input>

        <b-form-invalid-feedback id="input-image-url-feedback">
          Please enter a valid image URL
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <b-col cols="1">
      <i
          @click="addImage"
          class="fa-solid fa-circle-plus"></i>
    </b-col>
  </b-row>
  <b-row v-if="state.view">
    <b-col>
        <b-button
          @click="openModal"
          variant="primary">Save</b-button>
    </b-col>
  </b-row>
  <b-modal ref="confirmationModal" title="Update Banners Confirmation" hide-footer>
    <p class="atkm-confirmation-modal-text">Would you like to finalize these changes?</p>
    <b-button class="mt-3 atkm-add-event-button" variant="primary" @click="save">Save</b-button>
    <b-button class="mt-3 atkm-cancel-add-button" variant="primary" @click="closeModal">Close</b-button>
  </b-modal>
</template>

<script>
import axios from "axios";
import { BButton, BButtonGroup } from 'bootstrap-vue-next';

import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";

export default {
  name: "AtkmBannerConfigurePage",
  components: {
    AtkmWrappedBanner,
    BButton,
    BButtonGroup
  },
  computed: {
    imageUrlState() {
      return this.addImageUrl.length > 0;
    }
  },
  data() {
    return {
      state: {
        view: ''
      },
      addImageUrl: '',
      slides: [],
      urls: []
    }
  },
  methods: {
    addImage() {
      this.slides.push({
        image: this.addImageUrl
      });
      this.addImageUrl = '';
    },
    deleteImage(index) {
      this.slides.splice(index, 1);
    },
    save() {
      axios
        .post(`${process.env.VUE_APP_ATKM_API}/v1/banner`, {
          page: this.state.view,
          urls: this.slides.map(slide => slide.image)
        })
        .then(() => {
          this.state.success = true;
        })
        .catch(error => {
          console.log(error);
          this.state.error = error;
        });
    },
    setView(view) {
      this.state.view = view;
      axios
        .get(`${process.env.VUE_APP_ATKM_API}/v1/banner/${view}`)
        .then(response => {
          this.slides = response.data.banners.map(banner => {
            return {
              image: banner.url,
            }
          });
        })
        .catch(error => {
          console.log(error);
        });

    },
    openModal() {
      this.$refs.confirmationModal.show();
    },
    closeModal() {
      this.$refs.confirmationModal.hide();
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/atkm-admin-banner-configure.scss";
</style>