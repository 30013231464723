<template>
  <div class="atkm-our-pastors-container">
    <atkm-header />
    <atkm-wrapped-banner
        id="atkm-our-pastors-banner"
        title-class="no-text"
        :img-height="imageHeight"
        title="OUR PASTORS"
        :slides="ourPastorsSlides" />
    <atkm-our-pastors-info />
    <atkm-footer />
  </div>
</template>

<script>
import { getBannerHeight } from "@/utils/Header";
import AtkmHeader from "@/components/AtkmHeader";
import AtkmFooter from "@/components/AtkmFooter";
import AtkmOurPastorsInfo from "@/components/about/AtkmOurPastorsInfo";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";

export default {
  name: "AtkmOurPastors",
  computed: {
    imageHeight() {
      return getBannerHeight()
    }
  },
  data() {
    return {
      ourPastorsSlides: [
        {
          image: require("@/assets/banners/theme-bg.png")
        }
      ],
    }
  },
  components: {
    AtkmFooter,
    AtkmHeader,
    AtkmOurPastorsInfo,
    AtkmWrappedBanner,
  }
}
</script>

<style scoped>

</style>