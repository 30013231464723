<template>
  <div class="atkm-admin-header-container">
    <b-navbar>
      <b-navbar-brand href="/">
        <b-img
            fluid
            width="120"
            :src="require('@/assets/logos/logo-circle.png')"
            alt="Advancing The Kingdom Ministry"
            class="atkm-header-logo" />
      </b-navbar-brand>

      <b-navbar-nav>
<!--        <b-nav-item-dropdown-->
<!--            @mouseover="onMouseHover"-->
<!--            @mouseout="onMouseOut"-->
<!--            class="atkm-nav-link drop-down"-->
<!--            menu-class="atkm-nav-link-list border-1 rounded-0"-->
<!--            text="Banner"-->
<!--            no-caret>-->
<!--          <b-dropdown-item href="/admin/banner/home">Home</b-dropdown-item>-->
<!--          <b-dropdown-item href="/admin/banner/about-us">About Us</b-dropdown-item>-->
<!--          <b-dropdown-item href="/admin/banner/connect">Connect</b-dropdown-item>-->
<!--          <b-dropdown-item href="/admin/banner/events">Events</b-dropdown-item>-->
<!--          <b-dropdown-item href="/admin/banner/donate">Donate</b-dropdown-item>-->
<!--        </b-nav-item-dropdown>-->

        <b-nav-item-dropdown
            @mouseover="onMouseHover"
            @mouseout="onMouseOut"
            class="atkm-nav-link drop-down"
            menu-class="atkm-nav-link-list border-1 rounded-0"
            text="Events"
            no-caret>
          <b-dropdown-item href="/admin/events">View Events</b-dropdown-item>
          <b-dropdown-item href="/admin/add-event">Add Event</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "AtkmHeader",
  mounted() {
    const elems = document.querySelectorAll(".drop-down button");
    elems.forEach((elem) => {
      elem.style.color = "rgb(255, 255, 255) !important";
    });
  },
  methods: {
    onMouseHover(e) {
      e.target.style.color = "rgb(255, 255, 255) !important";
    },
    onMouseOut(e) {
      e.target.style.color = "rgb(255, 255, 255) !important";
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-admin-header.scss";
</style>