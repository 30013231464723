function epochToDateObject(epoch) {
    return new Date(epoch * 1000);
}

function epochToDayOfTheWeek(epoch) {
    const date = epochToDateObject(epoch)
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[date.getDay()];
}

function epochToDate(epoch) {
    return epochToDateObject(epoch).getDate();
}

function epochToMonth(epoch) {
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return months[epochToDateObject(epoch).getMonth()]
}

function epochToTime(epoch) {
    const date = epochToDateObject(epoch);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
}

function epochToMeridiem(epoch) {
    const date = epochToDateObject(epoch);
    const hours = date.getHours();
    return hours < 12 ? "A.M." : "P.M.";
}

export {
    epochToDayOfTheWeek,
    epochToDate,
    epochToMonth,
    epochToTime,
    epochToMeridiem
};