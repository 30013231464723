<template>
  <div class="atkm-prayer-request-container">
    <atkm-header />
    <atkm-wrapped-banner
      id="atkm-prayer-request-banner"
      title-class="no-text"
      :img-height="imageHeight"
      title="Prayer Request"
      :slides="prayerRequestSlides"/>
    <div class="atkm-container">
      <atkm-prayer-request-form />
    </div>
    <atkm-footer />
  </div>
</template>

<script>
import AtkmHeader from "@/components/AtkmHeader.vue";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner.vue";
import AtkmFooter from "@/components/AtkmFooter.vue";
import AtkmPrayerRequestForm from "@/components/connect/AtkmPrayerRequestForm.vue";
import {getBannerHeight} from "@/utils/Header";

export default {
  name: "AtkmPrayerRequest",
  computed: {
    imageHeight() {
      return getBannerHeight()
    }
  },
  data() {
    return {
      prayerRequestSlides: [
        {
          image: require("@/assets/banners/theme-bg.png")
        }
      ]
    }
  },
  components: {
    AtkmHeader,
    AtkmWrappedBanner,
    AtkmFooter,
    AtkmPrayerRequestForm
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-prayer-request.scss";
</style>