<template>
  <div class="atkm-home-container">
    <atkm-header />
    <atkm-wrapped-banner
      :on-slide="onSlide"
      id="atkm-home-banner"
      :content-class="homeBanner.contentClass"
      :title-class="homeBanner.titleClass"
      :text-class="homeBanner.textClass"
      :img-height="mainBannerHeight"
      :title="homeBanner.title"
      :text="homeBanner.text"
      :slides="slides">
    </atkm-wrapped-banner>
    <atkm-welcome />
    <atkm-calendar-content />
    <atkm-wrapped-banner
        id="atkm-watch-us-banner"
        title-class="no-text lowered"
        :img-height="imageHeight"
        title="WORSHIP WITH US"
        :slides="watchUsSlides" />
    <atkm-service-times />
<!--    <atkm-kingdom-kids-banner />-->
    <atkm-wrapped-banner
        id="atkm-kingdom-kids-banner"
        title-class="no-text lowered"
        :img-height="imageHeight"
        title="KINGDOM KIDS"
        :slides="kingdomKidsSlides" />
    <atkm-kingdom-kids-content />
    <atkm-footer />
  </div>
</template>

<script>
import { getMainBannerHeight , getBannerHeight } from "@/utils/Header";
import AtkmHeader from "@/components/AtkmHeader.vue";
import AtkmFooter from "@/components/AtkmFooter";
import AtkmWelcome from "@/components/home/AtkmWelcome";

// import axios from "axios";
import AtkmServiceTimes from "@/components/home/AtkmServiceTimes";
import AtkmKingdomKidsContent from "@/components/home/AtkmKingdomKidsContent";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";
import AtkmCalendarContent from "@/components/home/AtkmCalendarContent";

export default {
  name: "AtkmHome",
  beforeMount() {
    //this.slides = [];
    // axios
    //     .get(`${process.env.VUE_APP_ATKM_API}/v1/banner/home`)
    //     .then(response => {
    //       console.log(response.data.banners);
    //       response.data.banners.forEach(banner => {
    //         this.slides.push({
    //           caption: banner.caption,
    //           text: banner.text,
    //           image: banner.url
    //         });
    //       });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       this.slides = [{
    //         caption: "",
    //         text: "",
    //         image: this.bannerErrorImage
    //       }];
    //     });
  },
  props: {
    bannerErrorImage: {
      type: String,
      required: true,
      default: require("@/assets/banners/theme-bg.png")
    }
  },
  computed: {
    mainBannerHeight() {
      return getMainBannerHeight();
    },
    imageHeight() {
      return getBannerHeight();
    },
    homeBanner() {
      return this.slides[this.currentSlide];
    },
  },
  methods: {
    onSlide(slide) {
      this.currentSlide = slide;
    },
  },
  data() {
    return {
      currentSlide: 0,
      slides: [
        {
          title: "",
          text: "",
          image: require("@/assets/banners/welcome.png"),
        },
        {
          title: "",
          text: "",
          image: require("@/assets/banners/2024-banner.png"),
        },
        {
          title: "",
          text: "",
          image: require("@/assets/banners/praise-banner.jpg"),
        },
        {
          title: "",
          text: "",
          image: require("@/assets/banners/midweek-service-banner.jpg"),
        },
        {
          title: "",
          text: "",
          image: require("@/assets/banners/corporate-prayer.jpg"),
        },
        {
          title: "",
          text: "",
          image: require("@/assets/banners/noonday-prayer-banner.jpg"),
        }
      ],
      calendarSlides: [
        {
          image: require("@/assets/banners/2024-banner.png")
        }
      ],
      kingdomKidsSlides: [
        {
          caption: "",
          text: "",
          image: require("@/assets/banners/kingdom-kids.png")
        }
      ],
      watchUsSlides: [
        {
          image: require("@/assets/banners/worship-banner.png")
        }
      ]
    }
  },
  components: {
    AtkmKingdomKidsContent,
    AtkmServiceTimes,
    AtkmFooter,
    AtkmHeader,
    AtkmWelcome,
    AtkmWrappedBanner,
    AtkmCalendarContent
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/atkm-home.scss";
</style>