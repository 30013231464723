<template>
  <div class="atkm-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h1 class="atkm-header">Children's Church</h1>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-content-text sub normal-text">Every Sunday @ 11 A.M.</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-content-text sub normal-text">Every Wednesday @ 7:30 P.M.</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-youth-service-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-header">Youth Service</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-content-text sub normal-text">Every 5<sup>th</sup> Sunday</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AtkmKingdomKidsContent"
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-kingdom-kids-content.scss";
</style>