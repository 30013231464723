<template>
  <div class="atkm-events-container">
    <atkm-header v-if="showHeader"/>
    <atkm-wrapped-banner
        id="atkm-events-banner"
        title-class="no-text"
        :img-height="imageHeight"
        :title="title"
        :slides="slides" />
    <div class="atkm-container">
      <atkm-search placeholder="Enter event keyword, date, month, ministry, etc.."
                     @search="handleSearch"/>
      <atkm-event
          @delete-event="handleDeleteEvent"
          :admin="admin"
          v-show="!loading && events.length > 0"
          v-for="(event, index) in filteredEvents"
          :key="event.id"
          :id="event.id"
          :title="event.name"
          :description="event.description"
          :day="wrappedEpochToDayOfTheWeek(event.start_date)"
          :month="wrappedEpochToMonth(event.start_date)"
          :date="wrappedEpochToDate(event.start_date)"
          :time="wrappedEpochToTime(event.start_date)"
          :meridiem="wrappedEpochToMeridiem(event.start_date)"
          :resource="event.resource"
          :location="event.location"
          :location-link="event.location_link"
          :type="event.type"
          :ministries="event.ministries"
          :sub-event-name="event.sub_event_name"
          :sub-event-description="event.sub_event_description"
          :separator="index !== events.length - 1"/>
      <atkm-loading v-show="loading"/>
      <atkm-no-data
          message="No upcoming events"
          v-show="!loading && events.length === 0"/>
    </div>
    <atkm-footer />
  </div>
</template>

<script>
import {
  epochToDate,
  epochToDayOfTheWeek,
  epochToMonth,
  epochToTime,
  epochToMeridiem
} from "@/utils/Date";
import { getBannerHeight } from "@/utils/Header";
import AtkmNoData from "@/components/AtkmNoData";
import AtkmLoading from "@/components/AtkmLoading";
import AtkmEvent from "@/components/events/AtkmEvent";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";
import AtkmHeader from "@/components/AtkmHeader.vue";
import AtkmFooter from "@/components/AtkmFooter";
import AtkmSearch from "@/components/AtkmSearch";
import axios from "axios";

export default {
  name: "AtkmEvents",
  beforeMount() {
    this.loading = true;
    axios
        .get(
            `${process.env.VUE_APP_ATKM_API}/v1/event`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            })
        .then((response) => {
          if (response.data.events.length > 0) {
            this.events = response.data.events;
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
  },
  computed: {
    imageHeight() {
      return getBannerHeight();
    },
    filteredEvents() {
      return this.events.filter((event) => {
        return event.name.toLowerCase().includes(this.search.toLowerCase()) ||
            event.description.toLowerCase().includes(this.search.toLowerCase()) ||
            event.sub_event_name.toLowerCase().includes(this.search.toLowerCase()) ||
            event.sub_event_description.toLowerCase().includes(this.search.toLowerCase()) ||
            event.location.toLowerCase().includes(this.search.toLowerCase()) ||
            this.wrappedEpochToDate(event.start_date).toString().includes(this.search.toLowerCase()) ||
            this.wrappedEpochToDayOfTheWeek(event.start_date).toLowerCase().includes(this.search.toLowerCase()) ||
            this.wrappedEpochToMonth(event.start_date).toLowerCase().includes(this.search.toLowerCase()) ||
            this.wrappedEpochToTime(event.start_date).toLowerCase().includes(this.search.toLowerCase()) ||
            event.ministries.filter((ministry) => {
              return ministry.toLowerCase().includes(this.search.toLowerCase());
            }).length > 0
      }).sort((a, b) => {
        return a.start_date - b.start_date;
      });
    },
  },
  methods: {
    wrappedEpochToDayOfTheWeek(epoch) {
      return epochToDayOfTheWeek(epoch);
    },
    wrappedEpochToDate(epoch) {
      return epochToDate(epoch);
    },
    wrappedEpochToMonth(epoch) {
      return epochToMonth(epoch);
    },
    wrappedEpochToTime(epoch) {
      return epochToTime(epoch);
    },
    wrappedEpochToMeridiem(epoch) {
      return epochToMeridiem(epoch);
    },
    handleSearch(search) {
      this.search = search;
    },
    handleDeleteEvent(id) {
      this.$emit('delete-event', id)
    }
  },
  data() {
    return {
      events: [],
      loading: false,
      error: false,
      search: '',
      slides: [{
        image: require("@/assets/banners/theme-bg.png")
      }]
    };
  },
  props: {
    title: {
      type: String,
      default: "Events",
    },
    admin: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AtkmSearch,
    AtkmFooter,
    AtkmEvent,
    AtkmHeader,
    AtkmWrappedBanner,
    AtkmLoading,
    AtkmNoData
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-events.scss";
</style>