<template>
  <div class="atkm-form-info-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <b-img
            fluid
            :width="imageWidth"
            :src="imageUrl"/>
      </b-col>
      <b-col cols="2">
        <i @click="openModal"
           :style="{'display': editMode ? '' : 'none' }"
           class="fa-solid fa-trash atkm-delete-icon" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h1 class="atkm-header">{{ title }}</h1>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row
        class="atkm-form-description-row"
        :style="{'display': description ? '' : 'none' }">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">{{ description }}</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-view-document-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <a :href="documentUrl"
           target="_blank"
           class="atkm-button-link">
          View Document
        </a>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row :style="{'display': separator ? '' : 'none' }">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <hr />
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-modal ref="confirmationModal" title="Delete Form Confirmation" hide-footer>
      <p class="atkm-confirmation-modal-text">Would you like to delete this form?</p>
      <b-button class="mt-3 atkm-delete-form-button" variant="primary" @click="deleteForm">Delete Form</b-button>
      <b-button class="mt-3 atkm-cancel-delete-button" variant="primary" @click="closeModal">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
import {BRow, BCol} from "bootstrap-vue-next";
import axios from "axios";

export default {
  name: "AtkmFormInfo",
  props : {
    editMode: {
      type: Boolean,
      required: true,
      default: false
    },
    id: {
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    documentUrl: {
      type: String,
      required: true
    },
    imageUrl: {
      type: String,
      required: true
    },
    separator: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    imageWidth() {
      return window.innerWidth > 768 ? "500" : "300";
    }
  },
  methods: {
    openModal() {
      this.$refs.confirmationModal.show();
    },
    closeModal() {
      this.$refs.confirmationModal.hide();
    },
    deleteForm() {
      axios
          .delete(`${process.env.VUE_APP_ATKM_API}/v1/form/${this.id}`)
          .then(() => {
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      this.closeModal();
    }
  },
  components: {
    BRow,
    BCol
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-form.scss";
</style>