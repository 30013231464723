import { createApp } from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import BootstrapVue from "bootstrap-vue-next";

createApp(App)
    .use(BootstrapVue)
    .use(router)
    .use(store)
    .mount('#app')
