<template>
  <div class="atkm-giving-container">
    <atkm-header />
    <atkm-wrapped-banner
        id="atkm-events-banner"
        :img-height="imageHeight"
        title="4 WAYS TO GIVE"
        :slides="givingSlides" />
    <div class="atkm-container">
      <atkm-verse />
      <atkm-separator />
      <atkm-debt-free-fund />
      <atkm-separator />
      <atkm-online-giving />
      <atkm-separator class="atkm-online-giving-separator"/>
      <atkm-cash-app />
      <atkm-separator />
      <atkm-mail-checks />
      <atkm-separator />
      <atkm-in-service />
    </div>
    <atkm-footer />
  </div>
</template>

<script>
import { getBannerHeight } from "@/utils/Header";
import AtkmVerse from "@/components/giving/AtkmVerse";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";
import AtkmHeader from "@/components/AtkmHeader.vue";
import AtkmFooter from "@/components/AtkmFooter.vue";
import AtkmDebtFreeFund from "@/components/giving/AtkmDebtFreeFund";
import AtkmOnlineGiving from "@/components/giving/AtkmOnlineGiving";
import AtkmSeparator from "@/components/AtkmSeparator";
import AtkmMailChecks from "@/components/giving/AtkmMailChecks";
import AtkmInService from "@/components/giving/AtkmInService";
import AtkmCashApp from "@/components/giving/AtkmCashApp";

export default {
  name: "AtkmGiving",
  computed: {
    imageHeight() {
      return getBannerHeight();
    }
  },
  data() {
    return {
      givingSlides: [
        {
          image: require("@/assets/banners/theme-bg.png")
        }
      ]
    }
  },
  components: {
    AtkmCashApp,
    AtkmInService,
    AtkmMailChecks,
    AtkmSeparator,
    AtkmOnlineGiving,
    AtkmDebtFreeFund,
    AtkmHeader,
    AtkmFooter,
    AtkmWrappedBanner,
    AtkmVerse
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-giving.scss";
</style>