<template>
  <span class="atkm-tag">
    {{ name }}
  </span>
</template>

<script>
export default {
  name: "AtkmTag",
  props: {
    name: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-tag.scss";
</style>