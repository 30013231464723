<template>
  <div class="atkm-container">
    <atkm-belief
        v-for="(beliefItem, index) in sortedBeliefItems"
        :key="beliefItem.name"
        :name="beliefItem.name"
        :beliefs="beliefItem.beliefs"
        :separator="index !== sortedBeliefItems.length - 1"/>
  </div>
</template>

<script>
import AtkmBelief from "@/components/about/AtkmBelief";

export default {
  name: "AtkmOurBeliefsInfo",
  computed: {
    sortedBeliefItems() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.beliefItems.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
  },
  data() {
    return {
      beliefItems:[
        {
          name: 'Holy Trinity',
          beliefs: [
            {
              description: "The triune God the Father, the Son and the Holy Spirit; Jesus Christ is true God and true man",
              scriptures: [
                {
                  verse: "Genesis 1:1, 26",
                  link: "https://www.biblegateway.com/passage/?search=Genesis+1%3A26&version=KJV"
                },
                {
                  verse: "Matthew 28:19",
                  link: "https://www.biblegateway.com/passage/?search=Matt+28%3A19&version=KJV"
                },
                {
                  verse: "Acts 5:3-4, 9",
                  link: "https://www.biblegateway.com/passage/?search=Acts+5%3A3-9&version=KJV"
                },
                {
                  verse: "John 10:30",
                  link: "https://www.biblegateway.com/passage/?search=John+10%3A30&version=KJV"
                }
              ]
            },
            {
              description: "Gifts of the Father",
              scriptures: [
                {
                  verse: "Romans 12:3-8",
                  link: "https://www.biblegateway.com/passage/?search=Romans+12%3A3-8&version=KJV"
                },
                {
                  verse: "1 Corinthians 12:28",
                  link: "https://www.biblegateway.com/passage/?search=1+Corinthians+12%3A28&version=KJV"
                }
              ]
            },
            {
              description: "Gifts of the Son",
              scriptures: [
                    {
                      link: "https://www.biblegateway.com/passage/?search=Ephesians+4%3A11&version=KJV",
                      verse: "Ephesians 4:11"
                    }
              ]
            },
            {
              description: "Gifts of the Holy Spirit",
              scriptures: [
                    {
                      link: "https://www.biblegateway.com/passage/?search=1+Corinthians+12%3A8-10&version=KJV",
                      verse: "1 Corinthians 12:8-10"
                    }
              ]
            }
          ]
        },
        {
          name: "Our Savior",
          beliefs: [
            {
              description: "Healing is provided in the redemptive work of Christ and is available to every believer",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Matthew+8%3A17&version=KJV",
                  verse: "Matthew 8:17"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Isaiah+53%3A5&version=KJV",
                  verse: "Isaiah 53:5"
                }
              ]
            },
            {
              description: "The Church consists of all those who have received Jesus Christ as their personal Savior",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Ephesians+1%3A22-23&version=KJV",
                  verse: "Ephesians 1:22-23"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=1+Corinthians+12%3A12-15&version=KJV",
                  verse: "1 Corinthians 12:12-15"
                }
              ]
            },
            {
              description: "In the personal, visible, imminent return of Jesus Christ",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=1+Thessalonians+4%3A16&version=KJV",
                  verse: "1 Thessalonians 4:16"
                }
              ]
            }
          ]
        },
        {
          name: "Salvation",
          beliefs: [
            {
              description: "All have sinned and come short of the glory of God and are in need of salvation",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Romans+3%3A26&version=KJV",
                  verse: "Romans 3:26"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Romans+6%3A23&version=KJV",
                  verse: "Romans 6:23"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Romans+10%3A13&version=KJV",
                  verse: "Romans 10:13"
                }
              ]
            },
            {
              description: "Salvation has been provided through Jesus Christ for all men",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=John+3%3A16&version=KJV",
                  verse: "John 3:16"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Romans+6%3A23&version=KJV",
                  verse: "Romans 6:23"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Romans+10%3A9-10&version=KJV",
                  verse: "Romans 10:9-10"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Titus+2%3A11&version=KJV",
                  verse: "Titus 2:11"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Acts+16%3A31&version=KJV",
                  verse: "Acts 16:31"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Hebrews+9%3A22&version=KJV",
                  verse: "Hebrews 9:22"
                }
              ]
            },
            {
              description: "There shall be a bodily resurrection of the just and of the unjust",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Acts+24%3A15&version=KJV",
                  verse: "Acts 24:15"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=1+Thessalonians+4%3A13-17&version=KJV",
                  verse: "1 Thessalonians 4:13-17"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=1+Corinthians+15%3A20-23&version=KJV",
                  verse: "1 Corinthians 15:20-23"
                }
              ]
            }
          ]
        },
        {
          name: "Communion",
          beliefs: [
            {
              description: "Observance of the Lord's Supper",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=1+Corinthians+11%3A23-31&version=KJV",
                  verse: "1 Corinthians 11:23-31"
                }
              ]
            }
          ]
        },
        {
          name: "Word of God",
          beliefs: [
            {
              description: "The Bible is the inspired and the infallible, and the authoritative word of God",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=2+Timothy+3%3A16&version=KJV",
                  verse: "2 Timothy 3:16"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=2+Peter+1%3A20-21&version=KJV",
                  verse: "2 Peter 1:20-21"
                }
              ]
            }
          ]
        },
        {
          name: "Water Baptism",
          beliefs: [
            {
              description: "Symbol of Jesus Christ's death, burial, and resurrection",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Matthew+28%3A19&version=KJV",
                  verse: "Matthew 28:19"
                }
              ]
            }
          ]
        },
        {
          name: "Total Prosperity",
          beliefs: [
            {
              description: "Spiritual Prosperity",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=3+John+2&version=KJV",
                  verse: "3 John 2"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=2+Corinthians+5%3A17-21&version=KJV",
                  verse: "2 Corinthians 5:17-21"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Romans+10%3A9-10&version=KJV",
                  verse: "Romans 10:9-10"
                }
              ]
            },
            {
              description: "Mental Prosperity",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=2+Timothy+1%3A7&version=KJV",
                  verse: "2 Timothy 1:7"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Romans+12%3A2&version=KJV",
                  verse: "Romans 12:2"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Isaiah+26%3A3&version=KJV",
                  verse: "Isaiah 26:3"
                }
              ]
            },
            {
              description: "Physical Prosperity",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Isaiah+53%3A4-5&version=KJV",
                  verse: "Isaiah 53:4-5"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Matthew+8%3A17&version=KJV",
                  verse: "Matthew 8:17"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=1+Peter+2%3A24&version=KJV",
                  verse: "1 Peter 2:24"
                }
              ]
            },
            {
              description: "Financial Prosperity",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=3+John+1%3A2&version=KJV",
                  verse: "3 John 1:2"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Malachi+3%3A10-11&version=KJV",
                  verse: "Malachi 3:10-11"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Luke+6%3A38&version=KJV",
                  verse: "Luke 6:38"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=2+Corinthians+9%3A6-10&version=KJV",
                  verse: "2 Corinthians 9:6-10"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Deuteronomy+28%3A1-14&version=KJV",
                  verse: "Deuteronomy 28:1-14"
                }
              ]
            },
            {
              description: "Social Prosperity",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Proverbs+3%3A4&version=KJV",
                  verse: "Proverbs 3:4"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Luke+2%3A52&version=KJV",
                  verse: "Luke 2:52"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Acts+2%3A47&version=KJV",
                  verse: "Acts 2:47"
                }
              ]
            },
          ]
        },
        {
          name: "Holy Spirit",
          beliefs: [
            {
              description: "The Holy Spirit is a divine person",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=John+14%3A16-17&version=KJV",
                  verse: "John 14:16-17"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=John+16%3A7-13&version=KJV",
                  verse: "John 16:7-13"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Acts+1%3A8&version=KJV",
                  verse: "Acts 1:8"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=1+Corinthians+2%3A12&version=KJV",
                  verse: "1 Corinthians 2:12"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=1+Corinthians+3%3A16&version=KJV",
                  verse: "1 Corinthians 3:16"
                }
              ]
            },
            {
              description: "It is the will of God that every believer be filled with the Holy Spirit",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Acts+1%3A4-5&version=KJV",
                  verse: "Acts 1:4-5"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Luke+11%3A13&version=KJV",
                  verse: "Luke 11:13"
                },
                {
                  link: "https://www.biblegateway.com/passage/?search=Ephesians+5%3A18&version=KJV",
                  verse: "Ephesians 5:18"
                }
              ]
            }
          ]
        },
        {
          name: "Marriage",
          beliefs: [
            {
              description: "<b>Original definition of marriage came from God:</b><br /><br /><em class='atkm-scripture-quote'><sup>24</sup>Therefore shall a man leave his father and his mother, and shall cleave unto his wife: and they shall be one flesh.</em>",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Genesis+2%3A24&version=KJV",
                  verse: "Genesis 2:24"
                }
              ]
            },

            {
              description: "<b>Jesus Christ upheld this definition of marriage:</b><br /><br /><em class='atkm-scripture-quote'><sup>4</sup>And he answered and said unto them, Have ye not read, that he which made them at the beginning made them male and female,\n" +
                  "<br /><sup>5</sup> And said, For this cause shall a man leave father and mother, and shall cleave to his wife: and they twain shall be one flesh?\n" +
                  "<br /><sup>6</sup> Wherefore they are no more twain, but one flesh. What therefore God hath joined together, let not man put asunder.</em>",
              scriptures: [
                {
                  link: "https://www.biblegateway.com/passage/?search=Matthew+19%3A4-6&version=KJV",
                  verse: "Matthew 19:4-6"
                }
              ]
            }
          ]
        }
      ]
    }
  },
  components: {
    AtkmBelief,
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-our-beliefs-info.scss";
</style>