<template>
  <b-row>
    <b-col cols="2"></b-col>
    <b-col cols="8">
      <b-img
          id="atkm-calendar-img"
          fluid
          :width="imageWidth"
          :src="resource"/>
    </b-col>
    <b-col cols="2"></b-col>
  </b-row>
  <b-row class="atkm-view-calendar-row">
    <b-col cols="2"></b-col>
    <b-col cols="8">
      <a :href="documentUrl"
         target="_blank"
         class="atkm-button-link">
        View Calendar
      </a>
    </b-col>
    <b-col cols="2"></b-col>
  </b-row>
</template>

<script>
export default {
  name: 'AtkmCalendar',
  computed: {
    imageWidth() {
      return window.innerWidth > 768 ? "800" : "500"
    }
  },
  data() {
    let documentUrl = "/wp-content/uploads/forms/2024-calendar.pdf";
    if (process.env.NODE_ENV === 'development') {
      documentUrl = "/forms/2024-calendar.pdf";
    }
    return {
      resource: require("@/assets/img/2024-calendar.png"),
      documentUrl
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/atkm-calendar.scss";
</style>