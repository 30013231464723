<template>
  <atkm-email-form
    title=""
    messageFieldName="Request"
    messageFieldPlaceholder="Enter your prayer request here..."
    messageFeedback="A prayer request is required and must be between 1 and 500 characters"
    submittedMessage="Your prayer request has been submitted"
    :on-submit-fn="onSubmit"
  />
</template>

<script>
import axios from "axios";

import AtkmEmailForm from "@/components/connect/AtkmEmailForm.vue";

export default {
  name: "AtkmPrayerRequestForm",
  methods: {
    onSubmit(form) {
      return axios
        .post(`${process.env.VUE_APP_ATKM_API}/v1/prayer-request`, {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          message: form.message
        }, {
          headers: {
            "Content-Type": "application/json"
          }
        })
    }
  },
  components: {
    AtkmEmailForm
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-prayer-request-form.scss";
</style>