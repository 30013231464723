<template>
  <div class="atkm-loading-container">
    <i class="fa-light fa-spinner-third fa-spin fa-2xl"/>
  </div>
</template>

<script>
export default {
  name: "AtkmLoading"
}
</script>

<style lang="scss" scoped>
@import "../scss/atkm-loading.scss";
</style>