<template>
  <div class="atkm-forms-container">
    <atkm-header />
    <atkm-wrapped-banner
        id="atkm-events-banner"
        title-class="no-text"
        :img-height="imageHeight"
        title="FORMS"
        :slides="formSlides" />
    <div class="atkm-container">
      <atkm-search placeholder="Enter name or description keyword(s)..."
                     @search="handleSearch"/>
      <atkm-form-info
          class="atkm-form-info"
          :edit-mode="false"
          v-show="!state.loading && forms.length > 0"
          v-for="(form, index) in filteredForms"
          :key="form.id"
          :id="form.id"
          :title="form.name"
          :description="form.description"
          :document-url="form.document_url"
          :image-url="form.image_url"
          :separator="index !== forms.length - 1" />
    </div>
    <atkm-loading v-show="state.loading"/>
    <atkm-no-data
        message="No upcoming events"
        v-show="!state.loading && forms.length === 0 && !state.error"/>
    <atkm-footer />
  </div>
</template>

<script>
import { getBannerHeight } from "@/utils/Header";
import AtkmSearch from "@/components/AtkmSearch";
import AtkmHeader from "@/components/AtkmHeader";
import AtkmFooter from "@/components/AtkmFooter";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";
import AtkmFormInfo from "@/components/connect/AtkmFormInfo";
import AtkmLoading from "@/components/AtkmLoading";
import AtkmNoData from "@/components/AtkmNoData";

export default {
  name: "AtkmForms",
  computed: {
    imageHeight() {
      return getBannerHeight();
    },
    filteredForms() {
      return this.forms.filter((form) => {
        return form.name.toLowerCase().includes(this.search.toLowerCase()) ||
            form.description.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    handleSearch(search) {
      this.search = search;
    }
  },
  data() {
    let documentUrl = "/wp-content/uploads/forms/monthly-ministry-activity-log.pdf";
    if (process.env.NODE_ENV === 'development') {
      documentUrl = "/forms/monthly-ministry-activity-log.pdf";
    }
    return {
      forms: [
        {
          id: 1,
          name: "Monthly Ministry Activity Log",
          description: "Become a member of the ATKM",
          document_url: documentUrl,
          image_url: require("@/assets/img/monthly-ministry-activity-log.png")
        }
      ],
      formSlides: [
        {
          image: require("@/assets/banners/theme-bg.png")
        }
      ],
      search: "",
      state: {
        loading: false,
        error: false
      }
    }
  },
  components: {
    AtkmSearch,
    AtkmHeader,
    AtkmFooter,
    AtkmWrappedBanner,
    AtkmFormInfo,
    AtkmLoading,
    AtkmNoData
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-forms.scss";
</style>