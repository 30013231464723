<template>
  <div class="atkm-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <b-img
            fluid
            class="atkm-bishops-img"
            height="550"
            :src="require('@/assets/img/bishops.png')"/>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text normal-text">Advancing The Kingdom Ministry believes it is a biblical mandate for every church and leader to be connected and accountable in ministry, thereby ensuring the integrity of God’s ministry.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-content-text header">Pastor, Bishop, Dr. John E. Guns</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <b-img
            fluid
            @click="onConnectionClick('http://bishopjohnguns.org/')"
            class="atkm-connections-button"
            height="550"
            :src="require('@/assets/img/bishop-guns.png')"/>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-content-text header">St. Paul Church of Jacksonville</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <b-img
          fluid
          @click="onConnectionClick('https://spmbcjax.org/')"
          class="atkm-connections-button"
          height="550"
          :src="require('@/assets/img/spmbcjax.png')"/>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AtkmOurConnectionsInfo",
  methods: {
    onConnectionClick(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-our-connections.scss";
</style>