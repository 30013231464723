<template>
  <div class="atkm-giving-dff-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h1 class="atkm-header">Debt-Free Fund</h1>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">Our church is committed to advancing The Kingdom. As we continue to expand our reach and enhance our programs, having a stable and secure space is essential. Your donations to our Debt-Free Fund are exclusively dedicated to paying off the mortgage and maintenance costs of our facility, ensuring that we have a permanent home to carry out our mission effectively.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text">To designate a donation for the Debt-Free Fund, just add <b>"Debt-Free Fund"</b> to the donation message.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue-next";

export default {
  name: "AtkmDebtFreeFund",
  components: {
    BRow,
    BCol
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-debt-free-fund.scss";
</style>