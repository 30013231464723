<template>
  <div id="atkm-no-data-container">
    <h1 class="atkm-header" v-html="message"></h1>
  </div>
</template>

<script>
export default {
  name: "AtkmNoData",
  props: {
    message: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/atkm-no-data.scss";
</style>