<template>
  <div class="atkm-calendar-container">
    <atkm-header />
    <aktm-wrapped-banner
        id="atkm-calendar-banner"
        title="Calendar"
        :img-height="imageHeight"
        title-class="no-text"
        :slides=slides />
    <div class="atkm-container">
      <atkm-calendar />
    </div>
    <atkm-footer />
  </div>
</template>

<script>
import { getBannerHeight } from "@/utils/Header";
import AktmWrappedBanner from "@/components/AtkmWrappedBanner.vue";
import AtkmFooter from "@/components/AtkmFooter.vue";
import AtkmHeader from "@/components/AtkmHeader.vue";
import AtkmCalendar from "@/components/AtkmCalendar.vue";

export default {
  name: 'AtkmCalendarPage',
  components: {
    AtkmHeader,
    AtkmFooter,
    AktmWrappedBanner,
    AtkmCalendar
  },
  computed: {
    imageHeight() {
      return getBannerHeight();
    },
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/banners/theme-bg.png"),
        }
      ]
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/atkm-events-calendar.scss";
</style>