<template>
  <div class="atkm-contact-us-container">
    <atkm-header />
    <atkm-wrapped-banner
        id="atkm-events-banner"
        title-class="no-text"
        :img-height="imageHeight"
        title="CONTACT US"
        :slides="contactUsSlides" />
    <atkm-contact-info />
    <atkm-footer />
  </div>
</template>

<script>
import { getBannerHeight } from "@/utils/Header";
import AtkmHeader from "@/components/AtkmHeader";
import AtkmFooter from "@/components/AtkmFooter";
import AtkmContactInfo from "@/components/connect/AtkmContactInfo";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";

export default {
  name: "AtkmContactUs",
  computed: {
    imageHeight() {
      return getBannerHeight();
    }
  },
  data() {
    return {
      contactUsSlides: [
        {
          image: require("@/assets/banners/theme-bg.png")
        }
      ]
    }
  },
  components: {
    AtkmContactInfo,
    AtkmHeader,
    AtkmFooter,
    AtkmWrappedBanner
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-contact-us.scss";
</style>