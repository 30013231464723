function getMainBannerHeight() {
    if(window.innerWidth <= 480) {
        return "200";
    } else if (window.innerWidth <= 850) {
        return "350";
    }
    return "575";
}

function getBannerHeight() {
    if (window.innerWidth <= 480) {
        return "200";
    } else if (window.innerWidth <= 850) {
        return "300";
    }

    return "500";
}

export { getMainBannerHeight, getBannerHeight }