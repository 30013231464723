<template>
  <div class="atkm-giving-in-service">
    <b-row class="atkm-during-service-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-header">During Service</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">Sundays @ 11 AM<br />Wednesdays @ 7:30 PM</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue-next";

export default {
  name: "AtkmInService",
  components: {
    BRow,
    BCol
  },
}
</script>

<style lang="scss" scoped>

</style>