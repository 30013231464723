<template>
  <div class="atkm-footer-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <i @click="onEmailClick"
           class="fa-solid fa-at atkm-location-icon atkm-social-media-icon"></i>
        <i @click="onLocationClick"
           class="fa-sharp fa-solid fa-location-dot atkm-social-media-icon"></i>
        <i @click="onLinkClick('https://www.facebook.com/erica.blair.94695')"
           class="fa-brands fa-facebook atkm-social-media-icon"></i>
        <i @click="onLinkClick('https://www.youtube.com/@advancingthekingdomministr5836')"
           class="fa-brands fa-youtube atkm-social-media-icon"></i>
        <i @click="onLinkClick('https://www.instagram.com/atkmmedia/')"
           class="fa-brands fa-instagram atkm-social-media-icon"></i>
        <i @click="onLinkClick('https://twitter.com/ATKMMedia')"
           class="fa-brands fa-x-twitter atkm-social-media-icon"></i>
        <i @click="onLinkClick('https://open.spotify.com/show/4uJPOcxhhezZw3vIVUPLqY')"
           class="fa-brands fa-spotify atkm-social-media-icon"></i>
        <i @click="onLinkClick('https://iheart.com/podcast/161798252/')"
           class="fa-solid fa-tower-broadcast atkm-social-media-icon"></i>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-copyright-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-copyright-text">Copyright &copy; 2024 Advancing The Kingdom Ministry. All rights and content reserved.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue-next';

export default {
  name: "AtkmFooter",
  methods: {
    onEmailClick() {
      window.open("mailto:atkm352@gmail.com", "_self");
    },
    onLinkClick(url) {
      window.open(url, "_blank");
    },
    onLocationClick() {
      window.open("https://www.google.com/maps/dir/28.4327936,-81.6054272/advancing+the+kingdom/@28.6385868,-81.7746689,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x88e7a337ca879357:0x1c4ffc3a9400805!2m2!1d-81.6827916!2d28.8575899?entry=ttu", "_blank");
    },
  },
  components: {
    BRow,
    BCol
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/atkm-footer.scss";
</style>