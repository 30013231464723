// store.js
import Vuex from 'vuex';

export default new Vuex.Store({
    state: {
        expandedMenu: false,
        videoSize: 0
    },
    mutations: {
        updatedExpandedMenu(state, expandedMenu) {
            state.expandedMenu = expandedMenu;
        },
        updatedVideoSize(state, videoSize) {
            state.videoSize = videoSize;
        }
    },
    actions: {
        updateExpandedMenuAction({ commit }, expandedMenu) {
            commit('updatedExpandedMenu', expandedMenu);
        },
        updateVideoSizeAction({ commit }, videoSize) {
            commit('updatedVideoSize', videoSize);
        }
    }
});
