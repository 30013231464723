<template>
  <div class="atkm-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h1 class="atkm-header">Welcome</h1>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">We are delighted to welcome you to Advancing The Kingdom Ministry; where your life, your gifts, your talents and your family are greatly appreciated. We believe that it is by God’s ordained purpose that you are here.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">At Advancing The Kingdom Ministry, our doors are wide open to people from all backgrounds, regardless of where they are on their spiritual journey.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">We are a life-giving, multicultural church, whose vision is to inform, inspire, instruct and infuse believers with the understanding necessary to fulfill their God-given purpose.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">We pray and believe that because of being planted in this ministry you will discover your true identity as a son or daughter of God.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text signature-title">For His Kingdom</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text signature">Bishop John & Pastor Erica Blair</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AtkmWelcome"
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-welcome.scss";
</style>