<template>
  <div class="atkm-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-content-text header capitalize-text">Mission</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text">Advance the Kingdom of God</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-content-text header capitalize-text">Our Vision</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text">To inform, inspire, instruct and infuse believers with the understanding necessary to fulfill their God given purpose.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-content-text header capitalize-text">How we will accomplish our vision?</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text sub normal-text">Advancing The Kingdom Ministry will accomplish the vision through teaching <i>(imparting knowledge)</i> and hands-on training <i>(real life applications that make it easier to understand what is being taught)</i>. As a result, believers will be informed <i>(given knowledge and spiritual insight)</i>, inspired <i>(moved by divine inspiration)</i>, instructed <i>(furnished with directions)</i>, and infused <i>(poured into)</i> with the understanding <i>(knowledge)</i> necessary to fulfill <i>(carry out)</i> their God given purpose <i>(vocation – calling)</i>.</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue-next"

export default {
  name: "AtkmOurMissionInfo",
  components: {
    BRow,
    BCol
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-our-mission-info.scss";
</style>