<template>
  <div class="atkm-giving-mail-check">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-header">Check</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <p class="atkm-content-text">Mail Checks To:<br />P.O. BOX 350871<br />Grand Island, FL, 32735</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue-next";

export default {
  name: "AtkmMailChecks",
  components: {
    BRow,
    BCol
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-giving-mail-checks.scss";
</style>