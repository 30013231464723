<template>
  <div class="atkm-event-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8" class="atkm-event-flyer">
        <b-img
            fluid
            :width="imageWidth"
            v-if="resource && type === 'image'"
            :src="resource"/>
        <video
            controls
            :width="imageWidth"
            v-else-if="resource && type === 'video'">
          <source
              :src="resource"
              type="video/mp4"/>
        </video>
        <b-img
            fluid
            v-else
            class="atkm-default-event-img"
            :width="imageWidth"
            :src="defaultEventImg"/>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <i v-if="admin"
           @click="deleteEvent(this.id)"
           class="fa-solid fa-trash atkm-event-delete-icon" />
      </b-col>
      <b-col cols="8" class="atkm-header">
        <h1>{{ title }}</h1>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8" class="atkm-content-text sub event-description">
        <p>{{ description }}</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row :style="{ display: subEventName ? '' : 'none'}">
      <b-col cols="2"></b-col>
      <b-col cols="8" class="atkm-header">
        <h3>{{ subEventName }}</h3>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row :style="{ display: subEventDescription ? '' : 'none'}">
      <b-col cols="2"></b-col>
      <b-col cols="8" class="atkm-content-text sub event-description">
        <p>{{ subEventDescription }}</p>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8" class="atkm-header date">
        <h2>{{ day }} {{ month}} {{ date }} @ {{ time }} {{ meridiem }}</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8" class="atkm-content-text sub event-description">
        {{ location }}
        <a :href="locationLink" target="_blank" v-show="locationLink">
          <i class="atkm-location-icon fa-sharp fa-solid fa-location-dot"></i>
        </a>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row
        class="atkm-event-presented-by-row"
        :style="{ display: ministries.length > 0 ? '' : 'none'}">
      <b-col cols="2"></b-col>
      <b-col cols="8" class="atkm-header presented-by">
        <h5>Presented By</h5>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row v-for="ministry in ministries" :key="ministry">
      <b-col cols="2"></b-col>
      <b-col cols="8" class="atkm-content-text sub event-ministries">
        <atkm-tag :name="ministry" />
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row v-if="separator" class="atkm-separator-row">
      <b-col cols="4"></b-col>
      <b-col cols="4">
        <hr class="atkm-separator"/>
      </b-col>
      <b-col cols="4"></b-col>
    </b-row>
  </div>
</template>

<script>
import AtkmTag from "@/components/events/AtkmTag";

export default {
  name: "AtkmEvent",
  computed: {
    defaultEventImg() {
      return require("@/assets/logos/logo-circle.png");
    },
    imageWidth() {
      return window.innerWidth > 768 ? 650 : 300;
    }
  },
  methods:{
    deleteEvent() {
      this.$emit("delete-event", this.id);
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
    resource: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    meridiem: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    locationLink: {
      type: String,
      required: false,
    },
    separator: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: true,
      default: "image",
    },
    ministries: {
      type: Array,
      required: true,
    },
    subEventName: {
      type: String,
      required: false,
    },
    subEventDescription: {
      type: String,
      required: false,
    },
  },
  components: {
    AtkmTag
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-event.scss";
</style>