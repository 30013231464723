<template>
  <atkm-admin-container
      show-footer
      class="atkm-admin-container">
    <atkm-wrapped-banner
        id="atkm-events-banner"
        :img-height="imageHeight"
        title="ADMIN ADD EVENT"
        :slides="slides" />
    <div class="atkm-admin-add-event-container">
      <b-alert :show="eventAdded" dismissable>Event Added Successfully</b-alert>
      <b-alert :show="addEventError" dismissable>Error Adding Event {{ addEventError }}</b-alert>
      <b-row class="atkm-event-form">
        <b-col>
          <h1>Add Event Form</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form @submit="updatePreview">
            <b-form-group
                class="atkm-event-input-group"
                id="eventName"
                label="Event Name"
                label-for="name">
              <b-form-input
                  id="name"
                  v-model="event.name"
                  :state="nameState"
                  aria-describedby="input-name-feedback"
                  required
              ></b-form-input>

              <b-form-invalid-feedback id="input-name-feedback">
                Name must be between 1 and 55 characters
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
                class="atkm-event-input-group"
                id="eventDescription"
                label="Event Description"
                label-for="description"
                description="Enter a description of the event"
            >
              <b-form-textarea
                  id="description"
                  :state="descriptionState"
                  v-model="event.description"
                  aria-describedby="input-description-feedback"
              ></b-form-textarea>

              <b-form-invalid-feedback id="input-description-feedback">
                Description must contain no more than 255 characters
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
                class="atkm-event-input-group"
                id="startDate"
                label="Start Date"
                label-for="startDate"
                description="Enter the start date of the event in epoch timestamp format"
            >
              <b-form-input
                  id="startDate"
                  type="datetime-local"
                  v-model="event.startDate"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                class="atkm-event-input-group"
                id="resourceUri"
                label="Image URL"
                label-for="resourceUri"
                description="Enter the resource URI for the event"
            >
              <b-form-input
                  id="resourceUri"
                  v-model="event.resource"
              ></b-form-input>
            </b-form-group>

            <b-form-group
                class="atkm-event-input-group"
                id="location"
                label="Address"
                label-for="location"
                description="Enter the location of the event"
            >
              <b-form-input
                  id="location"
                  :state="locationState"
                  v-model="event.location"
                  aria-describedby="input-location-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-location-feedback">
                Location  must contain no more than 255 characters
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
                class="atkm-event-input-group"
                id="mapsLink"
                label="Address Link"
                label-for="mapsLink"
                description="Enter the Google Maps location link for the event"
            >
              <b-form-input
                  id="mapsLink"
                  :state="locationLinkState"
                  v-model="event.locationLink"
                  aria-describedby="input-location-link-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-location-link-feedback">
                Location link must contain no more than 1024 characters
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
                class="atkm-event-input-group"
                id="subEventName"
                label="Secondary Event Name"
                label-for="subEventName"
                description="Example: Pre-Service Prayer"
            >
              <b-form-input
                  id="subEventName"
                  :state="subEventNameState"
                  v-model="event.subEvent.name"
                  aria-describedby="input-sub-event-name-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input--sub-event-name-feedback">
                Sub-Event Name must contain no more than 55 characters
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
                class="atkm-event-input-group"
                id="subEventDescription"
                label="Secondary Event Description"
                label-for="subEventDescription"
                description="Example: Start 15 minutes prior to service"
            >
              <b-form-textarea
                  id="subEventDescription"
                  :state="subEventDescriptionState"
                  v-model="event.subEvent.description"
                  aria-describedby="input-sub-event-description-feedback"
              ></b-form-textarea>

              <b-form-invalid-feedback id="input-sub-event-description-feedback">
                Sub-Event Description must contain no more than 255 characters
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
                class="atkm-event-input-group"
                id="flyerType"
                label="Flyer Type"
                label-for="flyerType"
                description="Select the flyer type (image or video)"
            >
              <b-form-radio-group
                  id="flyerType"
                  v-model="event.flyerType"
                  :options="flyerTypes"
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group
                class="atkm-event-input-group"
                id="ministries"
                label="Presented By"
                label-for="ministries"
                description="(Optional) Select the ministries associated with the event"
            >
              <b-form-select
                  v-model="event.ministries"
                  :options="ministries"
                  multiple
              ></b-form-select>
            </b-form-group>

            <b-button
                class="atkm-add-event-button"
                type="submit"
                variant="primary">Update Preview</b-button>
          </b-form>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <h1>Event Preview</h1>
        </b-col>
      </b-row>
      <b-row class="atkm-event-preview-row">
        <b-col>
          <atkm-event
              :title="preview.name"
              :description="preview.description"
              :day="getDayOfWeek(preview.startDate)"
              :month="getMonth(preview.startDate)"
              :date="getDate(preview.startDate)"
              :time="getTime(preview.startDate)"
              :meridiem="getMeridiem(preview.startDate)"
              :resource="preview.resource"
              :location="preview.location"
              :location-link="preview.locationLink"
              :type="preview.flyerType"
              :ministries="preview.ministries"
              :sub-event-name="preview.subEvent.name"
              :sub-event-description="preview.subEvent.description"
              :separator="false"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
              v-show="state.previewUpdated"
              class="atkm-add-event-button"
              type="submit"
              variant="primary"
              @click="openModal">Add Event</b-button>
        </b-col>
      </b-row>
      <b-modal ref="confirmationModal" title="Add Event Confirmation" hide-footer>
        <p class="atkm-confirmation-modal-text">Would you like to add this event?</p>
        <b-button class="mt-3 atkm-add-event-button" variant="primary" @click="addEvent">Add Event</b-button>
        <b-button class="mt-3 atkm-cancel-add-button" variant="primary" @click="closeModal">Close</b-button>
      </b-modal>
    </div>
  </atkm-admin-container>
</template>

<script>
import axios from "axios";
import { BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup } from 'bootstrap-vue-next';

import { getBannerHeight } from "@/utils/Header";
import { getBannerSlideInterval } from "@/utils/Banner";
import AtkmAdminContainer from "@/components/admin/AtkmAdminContainer";
import AtkmEvent from "@/components/events/AtkmEvent";
import AtkmWrappedBanner from "@/components/AtkmWrappedBanner";

export default {
  name: 'AtkmAddEventPage',
  beforeMount() {
    axios
        .get(`${process.env.VUE_APP_ATKM_API}/v1/ministry`)
        .then(response => {
          this.ministries = response.data.ministries;
        });
  },
  components: {
    AtkmEvent,
    AtkmAdminContainer,
    AtkmWrappedBanner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormRadioGroup,
  },
  computed: {
    nameState() {
      return this.event.name.length > 0 && this.event.name.length <= 55;
    },
    descriptionState() {
      return this.event.description.length <= 255;
    },
    locationState() {
      return this.event.location.length <= 255;
    },
    locationLinkState() {
      return this.event.locationLink.length <= 1024;
    },
    subEventNameState() {
      return this.event.subEvent.name.length <= 55;
    },
    subEventDescriptionState() {
      return this.event.subEvent.description.length <= 255;
    },
    imageHeight() {
      return getBannerHeight();
    },
    slideInterval() {
      return getBannerSlideInterval();
    },
  },
  data() {
    return {
      state: {
        previewUpdated: false
      },
      slides: [
        {
          image: require("@/assets/banners/theme-bg.png")
        }
      ],
      eventAdded: false,
      addEventError: null,
      ministries: [],
      event: {
        name: 'Sunday Service',
        description: 'Come Worship With Us!',
        startDate: new Date().toISOString().slice(0, 16),
        endDate: '',
        resource: '',
        location: '417 North Grove Street, Eustis FL, 32726',
        locationLink: 'https://www.google.com/maps?s=web&channel=fs&client=ubuntu-sn&lqi=Ch5hZHZhbmNpbmcgdGhlIGtpbmdkb20gbWluaXN0cnlIiNXF-ZSrgIAIWjAQABABEAIQAxgAGAEYAhgDIh5hZHZhbmNpbmcgdGhlIGtpbmdkb20gbWluaXN0cnmSAQZjaHVyY2iqAUcQATIfEAEiG1ktkxuMhuzjgxQDtwdxHBGoAnFCL6zc7KlESzIiEAIiHmFkdmFuY2luZyB0aGUga2luZ2RvbSBtaW5pc3RyeeABAA&vet=12ahUKEwiSmv7v8fWBAxULDEQIHW4aAvAQ1YkKegQIHhAB..i&cs=0&um=1&ie=UTF-8&fb=1&gl=us&sa=X&geocode=KVeTh8o3o-eIMQUIQKnD_8QB&daddr=417+N+Grove+St,+Eustis,+FL+32726',
        subEvent: {
          name: 'Pre-Service Prayer',
          description: 'Starts at 10:45 AM',
        },
        flyerType: 'image',
        ministries: [],
      },
      preview: {
        name: '',
        description: '',
        startDate: new Date().toISOString().slice(0, 16),
        endDate: '',
        resource: '',
        location: '',
        locationLink: '',
        subEvent: {
          name: '',
          description: '',
        },
        flyerType: 'image',
        ministries: [],
      },
      flyerTypes: [
        { text: 'Image', value: 'image' },
        { text: 'Video', value: 'video' },
      ],
    };
  },
  methods: {
    addEvent() {
      // You can access the form data in this.event and submit it as needed
      // For example, you can send it to your backend API
      // Reset the form after submission
      const event = {
        name: this.event.name,
        description: this.event.description,
        start_date: Math.floor(new Date(this.event.startDate).getTime() / 1000),
        end_date: '',
        resource: this.event.resource,
        location: this.event.location,
        location_link: this.event.locationLink,
        sub_event_name: this.event.subEvent.name,
        sub_event_description: this.event.subEvent.description,
        type: this.event.flyerType,
        ministries: this.event.ministries,
      };

      // create axios with credentials
      const axiosInstance = new axios.create({
        withCredentials: true
      });
      const nonce = document.getElementById('atkm-id').value;
      axiosInstance
      .post(`${process.env.VUE_APP_ATKM_API}/v1/event`, event, {
        headers: {
          'X-WP-Nonce': nonce
        }
      })
        .then(() => {
          this.eventAdded = true;
        }).catch(
        error => {
          console.log(error);
          this.addEventError = error;
        })
        .finally(() => {
          this.closeModal();
          if(!this.addEventError) {
            window.location.reload();
          }
        });
    },
    openModal() {
      this.$refs.confirmationModal.show();
    },
    closeModal() {
      this.$refs.confirmationModal.hide();
    },
    getMonth(d) {
      // return the name of the month
      const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      return months[new Date(d).getMonth()];
    },
    getDate(d) {
      return new Date(d).getDate();
    },

    getDayOfWeek(d) {
      // return the day of the week
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return days[new Date(d).getDay()];
    },
    getMeridiem(d) {
      const hours = new Date(d).getHours();
      return hours < 12 ? "AM" : "PM";
    },
    getTime(d) {
      let hours = new Date(d).getHours();
      const minutes = new Date(d).getMinutes();
      hours = hours % 12;
      hours = hours ? hours : 12;
      return `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
    },
    updatePreview() {
      this.preview = this.event;
      this.state.previewUpdated = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-admin-add-event.scss";
</style>

