<template>
  <b-row class="atkm-form-search-container">
    <b-col :cols="outsideCols"></b-col>
    <b-col :cols="searchCols">
      <b-form-group class="atkm-search-form-group"
                    label="Search"
                    label-for="atkm-search">
      <b-form-input
          id="atkm-search"
          class="atkm-form-search-input"
          @input="performSearch"
          v-model="search"
          :placeholder="placeholder">
      </b-form-input>
      </b-form-group>
    </b-col>
    <b-col :cols="outsideCols"></b-col>
  </b-row>
</template>

<script>
export default {
  name: "AtkmSearch",
  computed: {
    searchCols() {
      return window.innerWidth > 768 ? 4 : 8;
    },
    outsideCols() {
      return (12 - this.searchCols) / 2;
    }
  },
  mounted() {
    const elem = document.querySelector("label[for=atkm-search]");
    elem.style['text-align'] = "left";
  },
  data() {
    return {
      search: ""
    }
  },
  methods: {
    performSearch() {
      this.$emit('search', this.search);
    }
  },
  props: {
    placeholder: {
      type: String,
      default: "Search"
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/atkm-search.scss";
</style>