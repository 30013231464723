<template>
  <label
      style="display: block; font-weight: bold; text-align: left; font-family: gabarito, sans-serif;"
      :class="classNames"
      :for="labelFor">{{ name }}<span class="atkm-required" v-if="required">*</span></label>
</template>

<script>
export default {
  name: "AtkmFormLabel",
  mounted() {
    window.innerWidth < 748 ? this.$el.style.fontSize = "1rem" : this.$el.style.fontSize = "1.25rem";
  },
  props: {
    name: {
      type: String,
      required: true
    },
    labelFor: {
      type: String,
      required: true
    },
    classNames: {
      type: String,
      required: false,
      default: "atkm-form-label"
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-form-label.scss";
</style>