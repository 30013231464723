import { createRouter, createWebHistory } from 'vue-router';

import FAtkmHome from '../components/AtkmHome.vue';
import AtkmAddEventPage from "@/components/admin/AtkmAddEventPage";
import AtkmViewEventsPage from "@/components/admin/AtkmViewEventsPage";
import AtkmAddFormPage from "@/components/admin/form/AtkmAddFormPage";
import AtkmViewFormsPage from "@/components/admin/form/AtkmViewFormsPage";
import AtkmBannerConfigurePage from "@/components/admin/banner/AtkmBannerConfigurePage";
import AtkmMinistries from "@/components/ministries/AtkmMinistries";
import AtkmEvents from "@/components/events/AtkmEvents";
import AtkmGiving from "@/components/giving/AtkmGiving";
import AtkmWatch from "@/components/watch/AtkmWatch";
import AtkmContactUs from "@/components/connect/AtkmContactUs";
import AtkmOurPastors from "@/components/about/AtkmOurPastors";
import AtkmOurMission from "@/components/about/AtkmOurMission";
import AtkmOurBeliefs from "@/components/about/AtkmOurBeliefs";
import AtkmForms from "@/components/connect/AtkmForms";
import AtkmOurConnections from "@/components/about/AtkmOurConnections";
import AtkmPrayerRequest from "@/components/connect/AtkmPrayerRequest";
import AtkmCalendarPage from "@/components/events/AtkmCalendarPage.vue";

const routes = [
    { path: '/', component: FAtkmHome },
    { path: '/about', component: AtkmOurPastors },
    { path: '/about/our-pastors', component: AtkmOurPastors },
    { path: '/about/our-mission', component: AtkmOurMission },
    { path: '/about/our-beliefs', component: AtkmOurBeliefs },
    { path: '/about/our-connection', component: AtkmOurConnections },
    { path: '/ministries', component: AtkmMinistries },
    { path: '/events', component: AtkmEvents },
    { path: '/events/calendar', component: AtkmCalendarPage },
    { path: '/giving', component: AtkmGiving },
    { path: '/watch', component: AtkmWatch },
    { path: '/connect', component: AtkmContactUs },
    { path: '/connect/contact-us', component: AtkmContactUs },
    { path: '/connect/prayer-request', component: AtkmPrayerRequest },
    { path: '/connect/forms', component: AtkmForms},
    { path: '/admin/add-event', component: AtkmAddEventPage },
    { path: '/admin/events', component: AtkmViewEventsPage },
    { path: '/admin/add-form', component: AtkmAddFormPage },
    { path: '/admin/forms', component: AtkmViewFormsPage },
    { path: '/admin/banners', component: AtkmBannerConfigurePage },
    { path: '/:notFound(.*)', redirect: '/' }
];

const router = new createRouter({
    history: createWebHistory(),
    routes
});

export default router;