<template>
  <atkm-email-form
      title="Contact Us"
      messageFieldName="Message"
      messageFieldPlaceholder="Enter your message here..."
      messageFeedback="A message is required and must be between 1 and 500 characters"
      submittedMessage="Your message has been submitted"
      :on-submit-fn="onSubmit"
  />
</template>

<script>
import axios from "axios";
import AtkmEmailForm from "@/components/connect/AtkmEmailForm";

export default {
  name: "AtkmEmailUsForm",
  methods: {
    onSubmit(form) {
      return axios
          .post(`${process.env.VUE_APP_ATKM_API}/v1/contact-us`, {
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            message: form.message
          }, {
            headers: {
              "Content-Type": "application/json"
            }
          })
    }
  },
  components: {
    AtkmEmailForm
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-email-us-form.scss";
</style>