<template>
  <div class="atkm-calendar-content-home">
    <div class="atkm-container">
      <atkm-calendar />
    </div>
  </div>
</template>

<script>
import AtkmCalendar from "@/components/AtkmCalendar.vue";
export default {
  name: "AtkmCalendarContent",
  components: {
    AtkmCalendar
  }
}
</script>

<style lang="scss">
@import "../../scss/atkm-calendar-content-home.scss";
</style>