<template>
  <router-view></router-view>
</template>

<script>
if (process.env.NODE_ENV === 'development') {
  require('./scss/_font-face.scss');
} else {
  require('./scss/font-face-prod.scss');
}
export default {
  name: 'App',
}
</script>

<style>
html,body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background-color: #FFF;
}
</style>
