<template>
  <!-- Contact Us form -->
  <div class="atkm-email-us-container">
    <b-container fluid v-show="!state.loading">
      <b-form @submit="onSubmit">
        <b-row :style="{ display: title.length > 0 ? '' : 'none' }">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <h1 class="atkm-header">{{ title }}</h1>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row :style="{ display: state.submitted || state.submitError ? 'none': '' }">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <h7 class="atkm-help-text">
              <span class="atkm-required">*</span> denotes a required field
            </h7>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row
            :style="{ display: state.submitted || state.submitError ? 'none': '' }"
            class="atkm-form-input-row">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <b-form-group>
              <atkm-form-label
                  required
                  label-for="firstName"
                  name="First Name" />
              <b-form-input
                  id="firstName"
                  class="atkm-form-input"
                  :state="firstNameValidation"
                  v-model="form.firstName"
                  type="text"
                  placeholder="John"
                  required>
              </b-form-input>
              <b-form-invalid-feedback :state="firstNameValidation">
                First name is required and must be between 1 and 50 characters
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row
            :style="{ display: state.submitted || state.submitError ? 'none': '' }"
            class="atkm-form-input-row">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <b-form-group>
              <atkm-form-label
                  required
                  name="Last Name"
                  label-for="lastName" />
              <b-form-input
                  id="lastName"
                  class="atkm-form-input"
                  :state="lastNameValidation"
                  v-model="form.lastName"
                  type="text"
                  placeholder="Doe"
                  required>
              </b-form-input>
              <b-form-invalid-feedback :state="lastNameValidation">
                Last name is required and must be between 1 and 50 characters
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row
            :style="{ display: state.submitted || state.submitError ? 'none': '' }"
            class="atkm-form-input-row">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <b-form-group>
              <atkm-form-label
                  required
                  name="Email"
                  label-for="email" />
              <b-form-input
                  id="email"
                  class="atkm-form-input"
                  :state="emailValidation"
                  v-model="form.email"
                  type="text"
                  placeholder="john.doe@gmail.com"
                  required>
              </b-form-input>
              <b-form-invalid-feedback :state="emailValidation">
                Email is required and must be between 1 and 80 characters
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row
            :style="{ display: state.submitted || state.submitError ? 'none': '' }"
            class="atkm-form-input-row">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <b-form-group>
              <atkm-form-label
                  required
                  name="Confirm Email"
                  label-for="confirmEmail" />
              <b-form-input
                  id="confirmEmail"
                  class="atkm-form-input"
                  :state="confirmEmailValidation"
                  v-model="form.confirmEmail"
                  type="text"
                  placeholder=""
                  required>
              </b-form-input>
              <b-form-invalid-feedback :state="confirmEmailValidation">
                Email and Confirm Email must match
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row
            :style="{ display: state.submitted || state.submitError ? 'none': '' }"
            class="atkm-form-input-row">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <b-form-group>
              <atkm-form-label
                  required
                  :name="messageFieldName"
                  label-for="message" />
              <b-form-textarea
                  id="message"
                  class="atkm-form-input"
                  :state="messageValidation"
                  v-model="form.message"
                  type="text"
                  :placeholder="messageFieldPlaceholder"
                  rows="8"
                  required>
              </b-form-textarea>
              <b-form-invalid-feedback :state="messageValidation">
                {{ messageFeedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row
            :style="{ display: state.submitted || state.submitError ? 'none': '' }"
            class="atkm-form-button-row">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <b-button
                :disabled="!formValidation"
                class="atkm-primary-button rounded-0"
                type="submit">
              Send
            </b-button>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row
            :style="{ display: state.submitted ? '': 'none' }"
            class="atkm-email-us-submitted-row">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <atkm-no-data
                id="atkm-email-us-submitted"
                :message="submittedMessage" />
          </b-col>
        </b-row>
        <b-row
            :style="{ display: state.submitError ? '': 'none' }"
            class="atkm-email-us-error-row">
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <atkm-no-data
                message="An unexpected error occurred<br /><br />Please try again later" />
          </b-col>
        </b-row>
      </b-form>
    </b-container>
    <atkm-loading v-show="state.loading" />
  </div>
</template>

<script>
import AtkmFormLabel from "@/components/form/AtkmFormLabel";
import AtkmNoData from "@/components/AtkmNoData";
import AtkmLoading from "@/components/AtkmLoading";

export default {
  name: "AtkmEmailForm",
  components: {AtkmNoData, AtkmFormLabel, AtkmLoading},
  props: {
    title: {
      type: String,
      required: true
    },
    messageFieldName: {
      type: String,
      required: true,
      default: "Message"
    },
    messageFieldPlaceholder: {
      type: String,
      required: true,
      default: "Enter your message here"
    },
    messageFeedback: {
      type: String,
      required: true,
      default: "A message is required and must be between 1 and 500 characters"
    },
    submittedMessage: {
      type: String,
      required: true,
      default: "Your message has been sent"
    },
    onSubmitFn: {
      type: Function,
      required: true
    }
  },
  computed: {
    firstNameValidation() {
      if (!this.state.firstNameEntered) {
        return null;
      }
      return this.form.firstName.length >= 1 && this.form.firstName.length <= 50;
    },
    lastNameValidation() {
      if (!this.state.lastNameEntered) {
        return null;
      }
      return this.form.lastName.length >= 1 && this.form.lastName.length <= 50;
    },
    emailValidation() {
      if (!this.state.emailEntered) {
        return null;
      }
      return this.form.email.length >= 1 && this.form.email.length <= 80;
    },
    confirmEmailValidation() {
      if (!this.state.confirmEmailEntered) {
        return null;
      }
      return this.form.email === this.form.confirmEmail;
    },
    messageValidation() {
      if (!this.state.messageEntered) {
        return null;
      }
      return this.form.message.length >= 1 && this.form.message.length <= 500;
    },
    formValidation() {
      return this.state.firstNameEntered &&
          this.state.lastNameEntered &&
          this.state.emailEntered &&
          this.state.confirmEmailEntered &&
          this.state.messageEntered &&
          this.firstNameValidation &&
          this.lastNameValidation &&
          this.emailValidation &&
          this.confirmEmailValidation &&
          this.messageValidation;
    }
  },
  methods: {
    onSubmit() {
      this.state.loading = true;
      this.onSubmitFn(this.form)
          .then(() => {
            this.state.submitted = true;
          })
          .catch(() => {
            this.state.submitError = true;
          })
          .finally(() => {
            this.state.loading = false;
          });
    }
  },
  data() {
    return {
      state: {
        firstNameEntered: false,
        lastNameEntered: false,
        emailEntered: false,
        confirmEmailEntered: false,
        messageEntered: false,
        submitted: false,
        submitError: false,
        loading: false
      },
      form: {
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        message: ""
      }
    }
  },
  watch: {
    'form.firstName': {
      handler() {
        this.state.firstNameEntered = true;
      },
      deep: true
    },
    'form.lastName': {
      handler() {
        this.state.lastNameEntered = true;
      },
      deep: true
    },
    'form.email': {
      handler() {
        this.state.emailEntered = true;
      },
      deep: true
    },
    'form.confirmEmail': {
      handler() {
        this.state.confirmEmailEntered = true;
      },
      deep: true
    },
    'form.message': {
      handler() {
        this.state.messageEntered = true;
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-email-form.scss";
</style>