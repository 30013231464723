<template>
  <div class="atkm-separator-container">
    <b-row class="atkm-separator-row">
      <b-col cols="4"></b-col>
      <b-col cols="4">
        <hr class="atkm-separator" />
      </b-col>
      <b-col cols="4"></b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue-next";

export default {
  name: "AtkmSeparator",
  components: {
    BRow,
    BCol
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/atkm-separator.scss";
</style>