<template>
  <div class="atkm-belief-container">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h1 class="atkm-header">{{ name }}</h1>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <ul class="atkm-beliefs"
            v-for="(belief, index) in beliefs"
            :key="index">
          <li>
            <h5 class="atkm-content-text" v-html="belief.description"></h5>
          </li>
          <li>
            <ul class="atkm-scriptures">
              <li
                  v-for="scripture in belief.scriptures"
                  :key="scripture"
                  class="atkm-scripture-list">
                <a
                    class="atkm-scripture-link"
                    :href="scripture.link"
                    target="_blank">
                  {{ scripture.verse }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-separator-row" v-if="separator">
      <b-col cols="4"></b-col>
      <b-col cols="4">
        <hr class="atkm-separator"/>
      </b-col>
      <b-col cols="4"></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AtkmBelief",
  props: {
    name: {
      type: String,
      required: true
    },
    beliefs: {
      type: Array,
      required: true
    },
    separator: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-belief.scss";
</style>