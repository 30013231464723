<template>
  <div class="atkm-giving-cashapp">
    <b-row class="atkm-cashapp-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <h2 class="atkm-header">Cash App</h2>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-cashapp-qr-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <b-img
            fluid
            :width="imageWidth"
            :src="require('@/assets/img/cashapp-qr-code.png')">
        </b-img>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    <b-row class="atkm-cashapp-content-row">
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <a class="atkm-button-link"
           target="_blank"
           :href="cashAppLink">
          Donate
        </a>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { getWidth } from "@/utils/Images";
import { getCashAppLink } from "@/utils/Url";
import { BImg, BRow, BCol } from "bootstrap-vue-next";

export default {
  name: "AtkmCashApp",
  computed: {
    cashAppLink() {
      return getCashAppLink();
    },
    imageWidth() {
      return getWidth();
    }
  },
  components: {
    BImg,
    BRow,
    BCol
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/atkm-giving-cashapp.scss";
</style>